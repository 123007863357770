import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewComponents } from 'constants/branding';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { updateAVMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip';
import { AsianViewMobileBetActions, TAsianMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip/type';

import styles from './styles.module.scss';

type MobileEditButtonProps = {
  bet: TAsianMobileSelectedBet;
};

const EditButton = ({ bet }: MobileEditButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);

  const editBetHandler = () => {
    if (bet.betslipId) {
      dispatch(
        updateAVMobileSelectedBet({
          betslipId: bet.betslipId,
          data: { action: AsianViewMobileBetActions.NEW }
        })
      );
    }
  };

  return (
    <button
      type="button"
      className={classNames(styles.btn, asianViewComponents.SECONDARY_BTN, {
        [styles.btn__landscape]: isLandscapeAsianView
      })}
      onClick={editBetHandler}
    >
      <span>{t('asianView.labels.placementActions.editBet')}</span>
    </button>
  );
};

export default EditButton;
