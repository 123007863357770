import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getGeneralWsEnabled,
  getIsCurrentBetsWebSocketEnabled,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import { fetchAsianCurrentBets } from 'redux/modules/asianViewCurrentBets';
import {
  EAsianPlacedQuickBettingStatus,
  TAsianQuickPlacedBet,
  TAsianQuickUpdatedBet
} from 'redux/modules/asianViewQuickBetting/type';

import PlacedOffer from '../PlacedOffer';

interface IPlacedQuickBetProps {
  quickBet: TAsianQuickPlacedBet & TAsianQuickUpdatedBet;
}
const PlacedQuickBet = ({ quickBet }: IPlacedQuickBetProps) => {
  const dispatch = useDispatch();

  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const isCurrentBetsWebSocketsEnabled = useSelector(getIsCurrentBetsWebSocketEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const isCreated = quickBet.status === EAsianPlacedQuickBettingStatus.CREATED;
  const isPlace = quickBet.status === EAsianPlacedQuickBettingStatus.PLACE;
  const isPlaced = quickBet.status === EAsianPlacedQuickBettingStatus.PLACED;
  const isError = quickBet.status === EAsianPlacedQuickBettingStatus.ERROR;

  useEffect(() => {
    if (arePropertiesLoaded && (!generalWsEnabled || !isCurrentBetsWebSocketsEnabled)) {
      dispatch(fetchAsianCurrentBets());
    }
  }, [quickBet.offerId, arePropertiesLoaded, isCurrentBetsWebSocketsEnabled, generalWsEnabled]);

  return <>{(isCreated || isPlace || isPlaced || isError) && <PlacedOffer quickBet={quickBet} />}</>;
};

export default PlacedQuickBet;
