import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import BetMax from 'components/AsianViewBetslip/components/AVSelectedBet/components/BetMax';
import BetMin from 'components/AsianViewBetslip/components/AVSelectedBet/components/BetMin';
import ActionButtons from 'components/AsianViewMobileBetslip/components/MobileSelectedBet/components/ActionButtons';
import EditButton from 'components/AsianViewMobileBetslip/components/MobileSelectedBet/components/EditButton';
import MobileInputControl from 'components/AsianViewMobileBetslip/components/MobileSelectedBet/components/InputControl';
import MobileMessages from 'components/AsianViewMobileBetslip/components/MobileSelectedBet/components/MessagesList';
import useAsianOdds from 'hooks/useAsianOdds';
import { useCurrency } from 'hooks/useCurrency';
import { getCurrency, getIsOperatorBettingLimitsEnabled } from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { updateAVMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip';
import {
  AsianViewMobileBetActions,
  TAsianMobileSelectedBet,
  TAsianMobileUpdateData
} from 'redux/modules/asianViewMobileBetslip/type';
import { getMarketPricesCurrency } from 'redux/modules/marketsPrices/selectors';
import { BetTypes } from 'types/bets';
import { EInputFieldTypes } from 'types/betslip';
import { validateSize } from 'utils/size';

import styles from './styles.module.scss';

type MobilePlacementProps = {
  bet: TAsianMobileSelectedBet;
  updateMobileBet: ({ size, profit, focusedField }: TAsianMobileUpdateData) => void;
};

const MobilePlacementForm = ({ bet, updateMobileBet }: MobilePlacementProps) => {
  const dispatch = useDispatch();
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);
  const defaultCurrency = useSelector(getCurrency);
  const currencyCode = useSelector(getMarketPricesCurrency(bet.marketId));
  const isOperatorBettingLimitsEnabled = useSelector(getIsOperatorBettingLimitsEnabled);
  const { t } = useTranslation();
  const currency = useCurrency(currencyCode);
  const betPrice = useAsianOdds({ bet });

  const isBack = bet.betType === BetTypes.BACK;
  const isNew = bet.action === AsianViewMobileBetActions.NEW;
  const isValidation = bet.action === AsianViewMobileBetActions.VALIDATION;
  const isProgress = bet.action === AsianViewMobileBetActions.PROGRESS;
  const isConfirm = bet.action === AsianViewMobileBetActions.CONFIRM;

  useEffect(() => {
    if (betPrice && !isProgress && !isConfirm) {
      updateMobileBet({ price: betPrice });
    }
  }, [isProgress, betPrice, isConfirm]);

  useEffect(() => {
    if (bet.focusedField !== EInputFieldTypes.PROFIT) {
      updateMobileBet({ size: bet.size, action: bet.action });
    }
  }, [bet.focusedField]);

  const handleValidateSizeInput = () => {
    const { isValid, validValue, errorMessage, type, messageId } = validateSize({
      size: bet.size,
      betType: bet.betType,
      currency,
      defaultCurrency,
      isOperatorBettingLimitsEnabled
    });

    if (!isValid && errorMessage?.text) {
      updateMobileBet({ size: validValue, ignoreValidation: true });

      if (bet.betslipId) {
        dispatch(
          updateAVMobileSelectedBet({
            betslipId: bet.betslipId,
            data: {
              validationMessage: t(errorMessage?.text, errorMessage.params ?? {}),
              validSizeValue: validValue,
              sizeValidationType: type,
              validationMessageId: messageId,
              isValid: true,
              action: AsianViewMobileBetActions.VALIDATION
            }
          })
        );
      }
    }
  };

  const resetError = () => {
    if (bet.betslipId) {
      dispatch(
        updateAVMobileSelectedBet({
          betslipId: bet.betslipId,
          data: {
            validationMessage: '',
            sizeValidationType: null,
            validationMessageId: null,
            action: AsianViewMobileBetActions.NEW
          }
        })
      );
    }
  };

  return (
    <>
      <div className={classNames(styles.wrap, { [styles.wrap__landscape]: isLandscapeAsianView })}>
        <div className={classNames(styles.row, { [styles.row__2]: isLandscapeAsianView })}>
          <div className={styles.row__col}>
            <MobileInputControl
              bet={bet}
              type={isBack ? EInputFieldTypes.SIZE : EInputFieldTypes.PROFIT}
              updateMobileBet={updateMobileBet}
              validateInput={handleValidateSizeInput}
            />
          </div>
          <div className={styles.row__col}>
            <MobileInputControl
              bet={bet}
              type={isBack ? EInputFieldTypes.PROFIT : EInputFieldTypes.SIZE}
              updateMobileBet={updateMobileBet}
              validateInput={handleValidateSizeInput}
            />
          </div>
          {isLandscapeAsianView && isConfirm && <EditButton bet={bet} />}
        </div>
        {(isNew || isValidation) && (
          <div className={classNames(styles.row, { [styles.row__1]: isLandscapeAsianView })}>
            <div className={classNames(styles.row__col, { [styles.row__col__inner]: !isLandscapeAsianView })}>
              <BetMax bet={bet} updateBet={updateMobileBet} betMaxClassName={styles.betMax} />
              <BetMin marketId={bet.marketId} />
            </div>
          </div>
        )}
      </div>
      <MobileMessages bet={bet} onClose={resetError} />
      <ActionButtons bet={bet} />
    </>
  );
};

export default MobilePlacementForm;
