import { ReactNode, useRef, useState } from 'react';
import classNames from 'classnames';

import useOnClickOutside from 'hooks/useOnClickOutside';

import styles from './styles.module.scss';

interface DropdownContainerProps {
  children: (isOpen: boolean) => ReactNode;
  items: JSX.Element;
  className?: string;
}

const DropdownContainer = ({ children, items, className }: DropdownContainerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const closeDropdown = () => setIsOpen(false);

  useOnClickOutside(dropdownRef, closeDropdown);

  return (
    <div ref={dropdownRef} className={classNames(styles.dropdownContainer, className)}>
      <div onClick={() => setIsOpen(currIsOpen => !currIsOpen)}>{children(isOpen)}</div>
      <div className={styles.dropdownContainer__itemsContainer}>
        {isOpen && (
          <ul className={classNames(styles.dropdownContainer__items)} onClick={closeDropdown}>
            {items}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropdownContainer;
