import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewMobileBetslip from 'components/AsianViewMobileBetslip';
import Runner from 'components/AsianViewPageModule/components/Runner';
import { MOBILE_MAX_DISPLAY_SIZE } from 'constants/app';
import {
  AsianViewMarketLink,
  AV_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT_2,
  AV_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT_3,
  AV_MOBILE_MARKET_SELECTIONS_COLUMNS_COUNT,
  AV_OUTRIGHT_DESKTOP_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT,
  COLUMNS_NAMES_HALF_TIME_FULL_TIME,
  MIN_WIDTH_RUNNER,
  TAB_COLUMN_COUNTER
} from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import { MARKET_TYPES } from 'constants/marketTypes';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import useWindowSize from 'hooks/useWindowSize';
import { getLanguage } from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView, getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { TAsianViewRunner } from 'redux/modules/asianView/type';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { generateArray } from 'utils/general';

import styles from './styles.module.scss';

interface RunnerProps {
  competitionId: string;
  marketId: string;
  sectionId: string;
  eventId: string | number;
  runners: TAsianViewRunner[];
  isOutRight?: boolean;
  width: number;
  marketLink?: string;
  marketType?: string;
  isExpandedCoupon?: boolean;
}

const Runners = ({
  competitionId,
  marketId,
  sectionId,
  eventId,
  runners,
  isOutRight,
  width,
  marketLink,
  marketType,
  isExpandedCoupon
}: RunnerProps) => {
  const { t } = useTranslation();

  const language = useSelector(getLanguage);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const { windowWidth } = useWindowSize(100);
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: sectionId,
    eventId: String(eventId),
    observerOptions: { rootMargin: '200px' },
    section: isOutRight
      ? MarketsPricesSocketParamsSections.AsianViewMiddleSection
      : MarketsPricesSocketParamsSections.AsianViewMiddleSectionHDPEventTabs
  });

  const maxItemInLine = TAB_COLUMN_COUNTER[marketType || ''] || Math.floor(width / MIN_WIDTH_RUNNER);
  const numberOfRunners = runners.length;
  let countItemInLine: number;

  if (!isOutRight && isLandscapeView) {
    if (windowWidth > MOBILE_MAX_DISPLAY_SIZE) {
      countItemInLine = AV_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT_3;
    } else {
      countItemInLine = AV_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT_2;
    }
  } else if (isMobileAsianView && !isLandscapeView) {
    countItemInLine = AV_MOBILE_MARKET_SELECTIONS_COLUMNS_COUNT;
  } else if (isOutRight) {
    countItemInLine = AV_OUTRIGHT_DESKTOP_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT;
  } else if (maxItemInLine > runners.length) {
    countItemInLine = runners.length;
  } else {
    countItemInLine = maxItemInLine;
  }

  const emptyBlocks = generateArray(
    numberOfRunners % countItemInLine != 0 ? countItemInLine - (numberOfRunners % countItemInLine) : 0
  );

  const totalLines = Math.ceil(numberOfRunners / countItemInLine);

  const getName = (name: string, index: number) => {
    if (marketType === MARKET_TYPES.halfTimeFullTime) {
      return t(`asianView.labels.market.${COLUMNS_NAMES_HALF_TIME_FULL_TIME[index]}`);
    }
    return name;
  };

  return (
    <div
      ref={ref}
      style={{ gridTemplateColumns: `repeat(${countItemInLine}, 1fr)` }}
      data-market-id={sectionId}
      data-event-id={eventId}
      data-market-prices={true}
      className={classNames(styles.runnersContainer, branding.COUPON_CONTAINER)}
    >
      {runners.map((runner, index) => {
        const line = Math.ceil((index + 1) / countItemInLine);
        const betslipId = `${marketId}_${Math.ceil((index + 1) / countItemInLine)}`;
        const isBetslipLine = (index + 1) % countItemInLine === 0;
        const isMobileBetslipEnabled = isMobileAsianView && (isBetslipLine || index + 1 === runners.length);

        return (
          <Runner
            competitionId={competitionId}
            eventId={eventId as string}
            marketId={marketId}
            key={`${runner.id}-${index}`}
            id={sectionId}
            runnerId={runner.id}
            runnerHandicap={runner.handicap}
            name={getName(runner.translations[language] || runner.name, index)}
            defaultName={runner.name}
            translatedName={runner.translations[language] || runner.name}
            marketLink={marketLink || AsianViewMarketLink.OUTRIGHT}
            betslipId={betslipId}
            customClass={classNames(branding.MAIN_CONTAINER_BORDER, {
              [styles.runnerBorder__right]: !isBetslipLine,
              [styles.runnerBorder__bottom]: line != totalLines,
              [branding.MARKET_BORDER_BG]: isMobileAsianView
            })}
            isExpandedCoupon={isExpandedCoupon}
          >
            {isMobileBetslipEnabled && (
              <div style={{ gridColumn: `1 / span ${countItemInLine}` }}>
                <AsianViewMobileBetslip betslipId={betslipId} />
              </div>
            )}
          </Runner>
        );
      })}
      {!isMobileAsianView
        ? emptyBlocks.map((item, index) => (
            <div key={`${item}-${index}`} className={classNames(styles.emptyRunner, branding.MARKET_BODY)} />
          ))
        : null}
    </div>
  );
};

export default memo(Runners);
