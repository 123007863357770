import { Fragment } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { getConsolidatedBets, getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { getSortedMatchedCurrentBets } from 'redux/modules/currentBets/selectors';
import { CookieNames } from 'types';
import { MatchTypes } from 'types/bets';

import OpenedBet from '../OpenedBet';
import OpenedBetsHeader from '../OpenedBetsHeader';

const OpenedBetsMatchedSorted = () => {
  const [cookies] = useCookies([CookieNames.BETSLIP_FILTER_MARKET, CookieNames.BETSLIP_FILTER_EVENT]);

  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const consolidateBets = useSelector(getConsolidatedBets);
  const sortedCurrentBets = useSelector(
    getSortedMatchedCurrentBets({
      isGameType: isGameBetSlip,
      consolidateBets,
      filterMarketId: cookies.FILTER_BETS_BY_MARKET_ID ?? '',
      filterEventId: cookies.FILTER_BETS_BY_EVENT_ID ?? ''
    })
  );

  return (
    <>
      {sortedCurrentBets.map((bet, index) => (
        <Fragment key={`${bet.offerId}_${bet.matchType ?? ''}`}>
          <OpenedBetsHeader
            bet={bet}
            prevBet={sortedCurrentBets[index - 1] ?? null}
            side={bet.side}
            matchType={MatchTypes.MATCHED}
            alwaysDisplay
          />
          <OpenedBet
            bet={bet}
            matchType={MatchTypes.MATCHED}
            betIndex={index}
            isFirst={index === 0}
            isLast={index === sortedCurrentBets.length - 1}
            side={bet.side}
          />
        </Fragment>
      ))}
    </>
  );
};

export default OpenedBetsMatchedSorted;
