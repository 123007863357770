import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PromotableTooltip from 'components/PromotableTooltip';
import { TOTAL_LIABILITY_PRECISION } from 'constants/placement';
import { BETSLIP_LIABILITY } from 'constants/tooltip';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getIsGameBetSlip, getTotalMarketsLiability } from 'redux/modules/betslip/selectors';
import { getUserCurrency } from 'redux/modules/user/selectors';
import { roundFloat } from 'utils/liability';

import styles from './styles.module.scss';

const TotalLiability = () => {
  const { t } = useTranslation();

  const isGameBetSlip = useSelector(getIsGameBetSlip);

  // TODO Betslip redesign market currency ticket
  // const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  // const marketCurrency = marketPrices?.currency;
  // const isCurrencyChanged =
  //   isMultiCurrencySupported && isLoggedIn && marketCurrency && userCurrency && userCurrency !== marketCurrency;
  // const isSelectedBetsEnabled = !isMultiCurrencySupported || !isCurrencyChanged;

  const userCurrency = useSelector(getUserCurrency);
  const liability = useSelector(getTotalMarketsLiability);

  const { noFormattedAmount: liabilityFormatted } = useFormatCurrency(
    roundFloat(liability || 0, TOTAL_LIABILITY_PRECISION),
    userCurrency ?? '',
    {
      isCheckIndian: true,
      noRounding: true
    }
  );

  return (
    <div className={styles.totalLiability}>
      <div className={styles.totalLiability__label}>
        <PromotableTooltip
          className={styles.totalLiability__tooltip}
          tooltipKey={BETSLIP_LIABILITY}
          isIconLarge
          isIconFirst
        >
          {t(isGameBetSlip ? 'betslip.labels.liability' : 'betslip.labels.totalLiability')}:
        </PromotableTooltip>
      </div>
      <div className={styles.totalLiability__value}>
        <span>{liabilityFormatted}</span>
      </div>
    </div>
  );
};

export default TotalLiability;
