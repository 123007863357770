import { useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import OpenedBetsMatchedBySide from 'components/Betslip/components/OpenedBetsMatchedBySide/OpenedBetsMatchedBySide';
import Icon from 'components/Icon';
import ProfitLossTablePopUpButton from 'components/ProfitLossTablePopUp/components/ProfitLossTablePopUpButton/ProfitLossTablePopUpButton';
import { ASIAN_VIEW_PL_TABLE_AVAILABLE_MARKETS } from 'constants/asianView';
import { betslipBranding } from 'constants/branding';
import { SPORT_BASE_URL } from 'constants/locations';
import { useEnabledView } from 'hooks/useEnabledView';
import { getSwapColorsFancyMarketsOnCricketEnabled } from 'redux/modules/appConfigs/selectors';
import { TCurrentBet, TGroupedEvent } from 'redux/modules/currentBets/type';
import { CookieNames, SportId } from 'types';
import { BetTypes } from 'types/bets';
import { BettingType } from 'types/markets';
import { getEventTime, getIsRaceType } from 'utils/openedBets';

import styles from './OpenBetsEvent.module.scss';

interface OpenBetsEventProps {
  eventData: TGroupedEvent;
}

const OpenBetsEvent = ({ eventData }: OpenBetsEventProps) => {
  const betData = (eventData.betsByType[BetTypes.BACK] || eventData.betsByType[BetTypes.LAY])[0];

  const swapColorsOnCricketEnabled = useSelector(getSwapColorsFancyMarketsOnCricketEnabled);
  const { t } = useTranslation();
  const { isMultiMarketView } = useEnabledView();
  const [cookies, setCookie] = useCookies([
    CookieNames.BETSLIP_FILTER_MARKET,
    CookieNames.BETSLIP_FILTER_SORTING,
    CookieNames.BETSLIP_FILTER_EVENT,
    CookieNames.BETSLIP_SORTED_EVENTS_COLLAPSED
  ]);
  const collapsedList: string[] = cookies.BETSLIP_SORTED_EVENTS_COLLAPSED?.split(',') ?? [];
  const isCollapsedCookieValue = collapsedList.includes(eventData.eventId);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsedCookieValue);

  const eventName = betData.mainEventName || betData.eventName;
  const eventId = eventData.mainEventId || eventData.eventId;
  const eventLink = betData.isOutright
    ? `${SPORT_BASE_URL}/${betData.eventTypeId}/competition/${eventData.competitionId}`
    : `${SPORT_BASE_URL}/${betData.eventTypeId}/event/${eventId}`;

  const { backBets, layBets, swappedBackBets, swappedLayBets } = useMemo(() => {
    const backBetsList = eventData.betsByType[BetTypes.BACK] || [];
    const layBetsList = eventData.betsByType[BetTypes.LAY] || [];

    if (swapColorsOnCricketEnabled) {
      const isCricketBet = (bet: TCurrentBet) =>
        String(bet.eventTypeId) === SportId.CRICKET && bet.bettingType === BettingType.LINE;

      const backList = backBetsList.filter(bet => !isCricketBet(bet));
      const cricketBackBets = backBetsList.filter(bet => isCricketBet(bet));
      const layList = layBetsList.filter(bet => !isCricketBet(bet));
      const cricketLayBets = layBetsList.filter(bet => isCricketBet(bet));

      return {
        backBets: backList,
        layBets: layList,
        swappedBackBets: cricketBackBets,
        swappedLayBets: cricketLayBets
      };
    }

    return {
      backBets: backBetsList,
      layBets: layBetsList
    };
  }, [eventData, swapColorsOnCricketEnabled]);

  const toggleCollapse = () => {
    let newList: string[];

    if (!isCollapsedCookieValue) {
      newList = [...collapsedList, eventData.eventId];
    } else {
      newList = collapsedList.filter((item: string) => item !== eventData.eventId);
    }

    setCookie(CookieNames.BETSLIP_SORTED_EVENTS_COLLAPSED, newList.join(), { path: '/' });
    setIsCollapsed(currIsCollapsed => !currIsCollapsed);
  };

  return (
    <div className={styles.event}>
      <div className={styles.event__header}>
        <div className={styles.event__header__info}>
          <button className={styles.event__header__collapseBtn} onClick={toggleCollapse}>
            <Icon
              fontFamily="fa2"
              iconClass="fa2-chevron-down-icon"
              className={classNames(styles.event__header__icon, {
                [styles.event__header__icon__collapsed]: !isCollapsed
              })}
            />
          </button>
          {betData.eventInPlay && (
            <span className={classNames(styles.event__header__liveLabel, betslipBranding.LIVE)}>
              {t('asianView.labels.betslip.live')}
            </span>
          )}
          <Link className={styles.event__link} to={eventLink}>
            {eventName}{' '}
            {getIsRaceType(betData.eventTypeId) && betData.marketStartDate && getEventTime(betData.marketStartDate)}
          </Link>
        </div>
        {String(betData.eventTypeId) === SportId.SOCCER && !betData.isOutright && (
          <ProfitLossTablePopUpButton
            isActive={eventData.isPNLAvailable}
            eventId={eventData.eventId}
            marketId={betData.marketId}
            isAsianView={false}
          />
        )}
      </div>
      {!isCollapsed && (
        <>
          <OpenedBetsMatchedBySide bets={backBets} side={BetTypes.BACK} isMultiMarketView={isMultiMarketView} />
          {swappedLayBets && (
            <OpenedBetsMatchedBySide bets={swappedLayBets} side={BetTypes.LAY} isMultiMarketView={isMultiMarketView} />
          )}
          {swappedBackBets && (
            <OpenedBetsMatchedBySide
              bets={swappedBackBets}
              side={BetTypes.BACK}
              isMultiMarketView={isMultiMarketView}
            />
          )}
          <OpenedBetsMatchedBySide bets={layBets} side={BetTypes.LAY} isMultiMarketView={isMultiMarketView} />
        </>
      )}
    </div>
  );
};

export default OpenBetsEvent;
