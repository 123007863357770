import { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { generateArray } from 'utils/general';

import styles from './styles.module.scss';

type VirtualKeyboardProps = {
  onKeyClick: (key: string) => void;
  onBackSpace: () => void;
  isWidthBorder?: boolean;
  threeRows?: boolean;
  isCouponSettings?: boolean;
  isAsianView?: boolean;
  shouldIgnoreBlurEvent?: boolean;
};

const VirtualKeyboard = ({
  onKeyClick,
  onBackSpace,
  shouldIgnoreBlurEvent,
  isWidthBorder = false,
  threeRows = false,
  isCouponSettings = false,
  isAsianView = false
}: VirtualKeyboardProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isCouponSettings && ref.current) {
      ref.current.scrollIntoView({ block: 'end' });
    }
  }, []);

  return (
    <div
      ref={ref}
      className={classNames(styles.keyboard, {
        [styles.keyboard__wideBorder]: isWidthBorder,
        [styles.keyboard__couponSettings]: isCouponSettings
      })}
    >
      <div
        className={classNames(styles.keyboard__smallBtnsWrapper, {
          [styles.keyboard__smallBtnsWrapper__wideBorder]: isWidthBorder
        })}
      >
        <div
          className={classNames(styles.keyboard__smallBtnsWrapper__row, {
            [styles.keyboard__smallBtnsWrapper__row__wideBorder]: isWidthBorder
          })}
        >
          {generateArray(threeRows ? 4 : 6, 1).map(value => {
            return (
              <button
                key={value}
                className={classNames(styles.cell, {
                  [styles.cell__couponSettings]: isCouponSettings,
                  [styles.avCell]: isAsianView || isCouponSettings
                })}
                onClick={() => onKeyClick(value.toString())}
                onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
              >
                {value}
              </button>
            );
          })}
        </div>
        <div
          className={classNames(styles.keyboard__smallBtnsWrapper__row, {
            [styles.keyboard__smallBtnsWrapper__row__wideBorder]: isWidthBorder
          })}
        >
          {generateArray(threeRows ? 4 : 3, threeRows ? 5 : 7).map(value => {
            return (
              <button
                key={value}
                className={classNames(styles.cell, {
                  [styles.cell__couponSettings]: isCouponSettings,
                  [styles.avCell]: isAsianView || isCouponSettings
                })}
                onClick={() => onKeyClick(value.toString())}
                onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
              >
                {value}
              </button>
            );
          })}
          {!threeRows &&
            ['0', '00', '.'].map(value => {
              return (
                <button
                  key={value}
                  className={classNames(styles.cell, {
                    [styles.cell__couponSettings]: isCouponSettings,
                    [styles.avCell]: isAsianView || isCouponSettings
                  })}
                  onClick={() => onKeyClick(value)}
                  onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
                >
                  {value}
                </button>
              );
            })}
        </div>
        {threeRows && (
          <div
            className={classNames(styles.keyboard__smallBtnsWrapper__row, {
              [styles.keyboard__smallBtnsWrapper__row__wideBorder]: isWidthBorder
            })}
          >
            {['9', '0', '00', '.'].map(value => {
              return (
                <button
                  key={value}
                  className={classNames(styles.cell, {
                    [styles.cell__couponSettings]: isCouponSettings,
                    [styles.avCell]: isAsianView || isCouponSettings
                  })}
                  onClick={() => onKeyClick(value)}
                  onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
                >
                  {value}
                </button>
              );
            })}
          </div>
        )}
      </div>
      <button
        className={classNames(styles.clearBtn, {
          [styles.clearBtn__wideBorder]: isWidthBorder,
          [styles.clearBtn__couponSettings]: isCouponSettings
        })}
        onClick={onBackSpace}
        onMouseDown={ev => shouldIgnoreBlurEvent && ev.preventDefault()}
      >
        <i className="ion-backspace" />
      </button>
    </div>
  );
};

export default VirtualKeyboard;
