import { useSelector } from 'react-redux';

import { getAsianMobilePlacedBets } from 'redux/modules/asianViewMobileBetslip/selectors';

import AsianViewMobilePlacedBet from '../AsianViewMobilePlacedBet';

import styles from './styles.module.scss';

const AsianViewMobileBetsPlacedMessages = () => {
  const placedBets = useSelector(getAsianMobilePlacedBets);

  return (
    <div className={styles.wrap}>
      {placedBets.map(bet => (
        <AsianViewMobilePlacedBet bet={bet} key={`${bet.offerId}_${bet.identifier}`} />
      ))}
    </div>
  );
};

export default AsianViewMobileBetsPlacedMessages;
