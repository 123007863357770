import { EBetslipFilters } from 'redux/modules/betslip/type';
import { getTranslationKeyByCommissionType } from 'utils/tooltip';

export const DISABLED_CLASS_NAME = 'biab_hide biab_hide-tooltip';
export const SHOW_CLASS_NAME = 'biab_tooltip-show';
export const CELLS_ON_MARKET_PAGE = 'CELLS_ON_MARKET_PAGE';
export const ACCOUNT_PAGE_SETTINGS_LINK = 'ACCOUNT_PAGE_SETTINGS_LINK';
export const ACCOUNT_PAGE_BETTING_PL_LINK = 'ACCOUNT_PAGE_BETTING_PL_LINK';
export const ACCOUNT_PAGE_ACCOUNT_STATUS_LINK = 'ACCOUNT_PAGE_ACCOUNT_STATUS_LINK';
export const BACK_ODDS_MATCHED = 'BACK_ODDS_MATCHED';
export const BACK_ODDS = 'BACK_ODDS';
export const BACK_SIZE = 'BACK_SIZE';
export const BACK_PROFIT = 'BACK_PROFIT';
export const LAY_ODDS_MATCHED = 'LAY_ODDS_MATCHED';
export const LAY_ODDS = 'LAY_ODDS';
export const LAY_SIZE_MATCHED = 'LAY_SIZE_MATCHED';
export const LAY_SIZE = 'LAY_SIZE';
export const LAY_PROFIT = 'LAY_PROFIT';
export const BACK_CELL = 'BACK_CELL';
export const BACK_COLUMN = 'BACK_COLUMN';
export const LAY_CELL = 'LAY_CELL';
export const LAY_COLUMN = 'LAY_COLUMN';
export const NET_OF_COMMISSION = 'NET_OF_COMMISSION';
export const BACK_SELECTED_UNMATCHED = 'BACK_SELECTED_UNMATCHED';
export const LAY_SELECTED_UNMATCHED = 'LAY_SELECTED_UNMATCHED';
export const BETSLIP_LIABILITY = 'BETSLIP_LIABILITY';
export const CASHOUT_ICON_ON_MARKET_PAGE = 'CASHOUT_ICON_ON_MARKET_PAGE';
export const BETSLIP_LM_BACKMATCHED = 'BETSLIP_LM_BACKMATCHED';
export const BETSLIP_LM_LAYMATCHED = 'BETSLIP_LM_LAYMATCHED';
export const BETSLIP_FULL_AVG_PRICE = 'BETSLIP_FULL_AVG_PRICE';
export const ACCOUNT_RESET_TO_DEFAULT_STATE = 'ACCOUNT_RESET_TO_DEFAULT_STATE';
export const COLUMN_NO_DRAW = 'COLUMN_NO_DRAW';
export const COLUMN_DRAW = 'COLUMN_DRAW';
export const IN_PLAY = 'IN_PLAY';
export const IN_NOT_PLAY = 'IN_NOT_PLAY';
export const BETSLIP_BACK_STAKE = 'BETSLIP_BACK_STAKE';
export const BETSLIP_LAY_STAKE = 'BETSLIP_LAY_STAKE';
export const ACCOUNT_MENU_MY_BETS_LINK = 'ACCOUNT_MENU_MY_BETS_LINK';
export const ACCOUNT_MENU_PL_LINK = 'ACCOUNT_MENU_PL_LINK';
export const ACCOUNT_MENU_STATEMENT_LINK = 'ACCOUNT_MENU_STATEMENT_LINK';
export const CASH_OUT = 'CASH_OUT';
export const MY_BETS_LINK = 'MY_BETS_LINK';
export const MY_BETS_PL_LINK = 'MY_BETS_PL_LINK';
export const STATUS_LINK = 'STATUS_LINK';
export const NOT_IN_PLAY = 'NOT_IN_PLAY';
export const MATCHED_TOTAL = 'MATCHED_TOTAL';
export const BETSLIP_CANCEL_BET = 'BETSLIP_CANCEL_BET';
export const BETSLIP_UPDATE_BET = 'BETSLIP_UPDATE_BET';
export const BETSLIP_IM_UPDATE_BET = 'BETSLIP_IM_UPDATE_BET';
export const BETSLIP_CONFIRM_CHECKBOX = 'BETSLIP_CONFIRM_CHECKBOX';
export const MARKET_RUNNER = 'MARKET_RUNNER';
export const AVG_PRICE = 'AVG_PRICE';
export const QUICK_BETS_HOVER_MESSAGE = 'QUICK_BETS_HOVER_MESSAGE';
export const VIDEO_STREAMING = 'VIDEO_STREAMING';
export const MATCH_STATISTICS = 'MATCH_STATISTICS';
export const FAVOURITES = 'FAVOURITES';
export const ASIAN_VIEW_CASH_OUT = 'ASIAN_VIEW_CASH_OUT';
export const ASIAN_VIEW_BACK_LABEL = 'ASIAN_VIEW_BACK_LABEL';
export const ASIAN_VIEW_LAY_LABEL = 'ASIAN_VIEW_LAY_LABEL';
export const ASIAN_VIEW_LIMITED_OFFERING = 'ASIAN_VIEW_LIMITED_OFFERING';
export const ASIAN_VIEW_BETTING_DAY = 'ASIAN_VIEW_BETTING_DAY';
export const RESPONSIBLE_BETTING = 'RESPONSIBLE_BETTING';
export const BETSLIP_ODDS = 'BETSLIP_ODDS';
export const BETSLIP_BACKERS_ODDS = 'BETSLIP_BACKERS_ODDS';
export const BETSLIP_STAKE = 'BETSLIP_STAKE';
export const BETSLIP_BACKERS_STAKE = 'BETSLIP_BACKERS_STAKE';
export const BETSLIP_UNITS = 'BETSLIP_UNITS';
export const BETSLIP_BACKERS_UNITS = 'BETSLIP_BACKERS_UNITS';

export const getTooltipMessage = (isDesktop: boolean, isPNCEnabled: boolean): Record<string, string> => {
  return isDesktop
    ? {
        [BACK_ODDS_MATCHED]: 'tooltip.backOddsMatched',
        [LAY_SIZE_MATCHED]: 'tooltip.laySizeMatched',
        [BACK_SIZE]: 'tooltip.backSize',
        [LAY_SIZE]: 'tooltip.laySize',
        [BACK_PROFIT]: 'tooltip.backProfit',
        [LAY_PROFIT]: 'tooltip.layProfit',
        [BETSLIP_LIABILITY]: 'tooltip.betslipLiability',
        [BACK_ODDS]: getTranslationKeyByCommissionType(isPNCEnabled, 'tooltip.backOdds'),
        [LAY_ODDS]: 'tooltip.layOdds',
        [LAY_ODDS_MATCHED]: 'tooltip.layOddsMatched',
        [NET_OF_COMMISSION]: 'tooltip.profitNetOfCommission',
        [ACCOUNT_MENU_MY_BETS_LINK]: 'tooltip.accountMenu.myBetsLink',
        [ACCOUNT_MENU_PL_LINK]: 'tooltip.accountMenu.myBetsPLLink',
        [ACCOUNT_MENU_STATEMENT_LINK]: 'tooltip.accountMenu.statusLink',
        [CELLS_ON_MARKET_PAGE]: getTranslationKeyByCommissionType(isPNCEnabled, 'tooltip.all.pages.cells'),
        LM_LAY_SELECTED_UNMATCHED: 'betslip.tooltip.lm.laySelectedUnmatched',
        LM_BACK_SELECTED_UNMATCHED: 'betslip.tooltip.lm.backSelectedUnmatched',
        [CASH_OUT]: 'tooltip.cashOut',
        [MY_BETS_LINK]: 'tooltip.accountMenu.myBetsLink',
        [MY_BETS_PL_LINK]: 'tooltip.accountMenu.myBetsPLLink',
        [STATUS_LINK]: 'tooltip.accountMenu.statusLink',
        [COLUMN_DRAW]: 'tooltip.columnDraw',
        [COLUMN_NO_DRAW]: 'tooltip.columnNoDraw',
        [IN_PLAY]: 'tooltip.inPlayMarket',
        [NOT_IN_PLAY]: 'tooltip.notInPlayMarket',
        [MATCHED_TOTAL]: getTranslationKeyByCommissionType(isPNCEnabled, 'tooltip.matchedTotal'),
        [BACK_CELL]: getTranslationKeyByCommissionType(isPNCEnabled, 'tooltip.backCell'),
        [LAY_CELL]: getTranslationKeyByCommissionType(isPNCEnabled, 'tooltip.layCell'),
        [BACK_COLUMN]: 'tooltip.backColumn',
        [LAY_COLUMN]: 'tooltip.layColumn',
        [BACK_SELECTED_UNMATCHED]: 'betslip.tooltip.lm.backSelectedUnmatched',
        [LAY_SELECTED_UNMATCHED]: 'betslip.tooltip.lm.laySelectedUnmatched',
        [CASHOUT_ICON_ON_MARKET_PAGE]: 'tooltip.cashOut',
        [BETSLIP_LM_BACKMATCHED]: 'betslip.tooltip.lm.backMatched',
        [BETSLIP_LM_LAYMATCHED]: 'betslip.tooltip.lm.layMatched',
        [BETSLIP_FULL_AVG_PRICE]: 'betslip.tooltip.avgPrice',
        [ACCOUNT_RESET_TO_DEFAULT_STATE]: 'tooltip.account.quickBets.resetToDefaultState',
        [IN_NOT_PLAY]: 'tooltip.notInPlayMarket',
        [BETSLIP_BACK_STAKE]: 'tooltip.betslip.back.stake',
        [BETSLIP_LAY_STAKE]: 'tooltip.betslip.lay.stake',
        [BETSLIP_CANCEL_BET]: 'tooltip.betslipCancelBet',
        [BETSLIP_UPDATE_BET]: 'tooltip.betslipUpdateBet',
        [BETSLIP_CONFIRM_CHECKBOX]: 'tooltip.betslipConfirmCheckbox',
        [MARKET_RUNNER]: 'tooltip.market.runner',
        [BETSLIP_IM_UPDATE_BET]: 'betslip.tooltip.lm.updateBet',
        [QUICK_BETS_HOVER_MESSAGE]: 'tooltip.quickBets.hoverMessage',
        [VIDEO_STREAMING]: 'tooltip.videostreaming',
        [MATCH_STATISTICS]: 'tooltip.matchStatistics',
        [FAVOURITES]: 'tooltip.favorites',
        [ASIAN_VIEW_CASH_OUT]: 'tooltip.asianView.cashOut',
        [ASIAN_VIEW_BACK_LABEL]: 'tooltip.asianView.backLabel',
        [ASIAN_VIEW_LAY_LABEL]: 'tooltip.asianView.layLabel',
        [ASIAN_VIEW_LIMITED_OFFERING]: 'tooltip.asianView.limitedOffering',
        [ASIAN_VIEW_BETTING_DAY]: 'tooltip.asianView.bettingDay'
      }
    : {
        [CELLS_ON_MARKET_PAGE]: getTranslationKeyByCommissionType(isPNCEnabled, 'tooltip.cells'),
        [BETSLIP_ODDS]: 'tooltip.betslip.back.price',
        [BETSLIP_UNITS]: 'tooltip.betslip.back.units',
        [BETSLIP_STAKE]: 'tooltip.betslip.back.stake',
        [BETSLIP_BACKERS_ODDS]: 'tooltip.betslip.lay.price',
        [BETSLIP_BACKERS_UNITS]: 'tooltip.betslip.lay.units',
        [BETSLIP_BACKERS_STAKE]: 'tooltip.betslip.lay.stake',
        [BETSLIP_LIABILITY]: 'tooltip.betslip.liability',
        NET_OF_COMMISSION: 'tooltip.betslip.netProfit',
        CASH_OUT: 'tooltip.cashout',
        ACCOUNT_PAGE_BETTING_PL_LINK: 'tooltip.account.page.pl',
        ACCOUNT_PAGE_ACCOUNT_STATUS_LINK: 'tooltip.account.page.link',
        ACCOUNT_PAGE_SETTINGS_LINK: 'tooltip.account.page.settings.link',
        [CASHOUT_ICON_ON_MARKET_PAGE]: 'tooltip.cashOut',
        AVG_PRICE: 'betslip.tooltip.avgPrice',
        [ASIAN_VIEW_BETTING_DAY]: 'tooltip.asianView.bettingDay'
      };
};

const betslipFiltersTooltips = {
  [EBetslipFilters.MARKET]: 'tooltip.filter.byMarket',
  [EBetslipFilters.EVENT]: 'betslip.tooltip.openBets.filter.allEvents',
  [EBetslipFilters.SORTING]: 'tooltip.filter.showSortedBets'
};

/**
 * Adds 'Active' to translation key if filter is selected
 *
 * @param filter
 * @param isActive
 */
export const getBetslipFilterTooltip = (filter: EBetslipFilters, isActive: boolean) => {
  if (filter === EBetslipFilters.MARKET && isActive) {
    return `${betslipFiltersTooltips[EBetslipFilters.EVENT]}Active`;
  }

  return `${betslipFiltersTooltips[filter]}${isActive ? 'Active' : ''}`;
};

export const TOOLTIP_COMPONENT = {
  MARKET_IN_PLAY: 'MARKET_IN_PLAY'
};
