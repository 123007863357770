import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { betslipBranding as branding } from 'constants/branding';
import useBetslipOpenBetsSorting from 'hooks/useBetslipOpenBetsSorting';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { MatchTypes } from 'types/bets';

import BetPLButtonAndCashOut from '../BetPLButtonAndCashOut/BetPLButtonAndCashOut';

import styles from './styles.module.scss';

type BetInfoProps = {
  bet: TCurrentBet;
  matchType: MatchTypes;
};

const BetInfo = ({ bet, matchType }: BetInfoProps) => {
  const { t } = useTranslation();

  const isGameBetSlip = useSelector(getIsGameBetSlip);

  const formattedDate = useLongDateFormat({
    isTimeFirst: true,
    isShortYear: true,
    time: bet.placedDate ?? 0,
    hasYear: true,
    divider: ' ',
    dateDivider: '-'
  });
  const { isSortedByEvents } = useBetslipOpenBetsSorting();

  return (
    <div
      className={classNames(styles.betInfoContent, branding.OPENED_BET_PLACED_TIME, {
        [styles.betInfoContent__unmatched]: matchType === MatchTypes.UNMATCHED
      })}
    >
      <p>
        {t('betslip.labels.submitted')}: {formattedDate}
      </p>
      <div className={styles.betInfoContent__right}>
        <p>
          {t('betslip.labels.reference')}: {bet.offerId}
        </p>
        {!isGameBetSlip && <BetPLButtonAndCashOut bet={bet} matchType={matchType} hidePLButton={isSortedByEvents} />}
      </div>
    </div>
  );
};

export default BetInfo;
