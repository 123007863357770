import { ReactNode, useRef, useState } from 'react';
import classNames from 'classnames';
import { unescape } from 'lodash';
import { useTimeout } from 'usehooks-ts';

import { asianViewBetslipBranding } from 'constants/branding';
import useOnClickOutside from 'hooks/useOnClickOutside';

import styles from './styles.module.scss';

type MobileMessageProps = {
  message: string | ReactNode;
  type: 'error' | 'info';
  onClose?: () => void;
  onClickOutside?: () => void;
  isLockIcon?: boolean;
  className?: string;
};

const MobileMessage = ({
  message,
  type,
  className,
  onClickOutside,
  onClose = () => {},
  isLockIcon = false
}: MobileMessageProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isClickOutsideEnabled, setIsClickOutsideEnabled] = useState(false);

  useTimeout(() => {
    setIsClickOutsideEnabled(true);
  }, 500);

  useOnClickOutside(ref, undefined, () => {
    if (isClickOutsideEnabled && onClickOutside) {
      onClickOutside();
    }
  });

  const isInfo = type === 'info' && !isLockIcon;
  const isError = type === 'error' && !isLockIcon;

  return (
    <div
      ref={ref}
      className={classNames(
        styles.wrap,
        {
          [asianViewBetslipBranding.LOCKED_BET_MSG]: isLockIcon,
          [styles.wrap__info]: isInfo,
          [styles.wrap__error]: isError
        },
        className
      )}
    >
      {isLockIcon && <i className={classNames('biab_lock-icon fa2 fa2-lock', styles.lockIcon)} />}
      {isInfo && (
        <i className={classNames(styles.leftIcon__info, 'av-icon av-icon-info')}>
          <span className="path1" />
          <span className="path2" />
        </i>
      )}
      {isError && (
        <div className={styles.wrap__error__container}>
          <i className={classNames(styles.leftIcon__error, 'av-icon av-icon-error')} />
          <i
            className={classNames(
              styles.leftIcon__error,
              styles.leftIcon__error__line,
              'av-icon av-icon-error-left-line'
            )}
          />
          <i
            className={classNames(
              styles.leftIcon__error,
              styles.leftIcon__error__line,
              'av-icon av-icon-error-right-line'
            )}
          />
        </div>
      )}
      {typeof message === 'string' ? <span dangerouslySetInnerHTML={{ __html: unescape(message) }} /> : message}
      {isError && <i onClick={onClose} className={classNames('biab_tour-icon-close', styles.closeIcon)} />}
    </div>
  );
};

export default MobileMessage;
