import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { entries, isEmpty, isObject, isUndefined, keys, mergeWith, union, values } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import {
  ASIAN_SELECTED_BETS_AMOUNT_LIMIT,
  ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME,
  AsianViewSelectedBetStates,
  DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS
} from 'constants/asianView';
import { ASIAN_VIEW_PLACE_BET_ERRORS_IDS } from 'constants/betslip';
import api from 'redux/api/methods';
import { EAsianBettingActions } from 'redux/modules/asianViewQuickBetting/type';
import { EPlaceBetsStates } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { EAsianBetslipTabs } from 'types/asianView';
import { getIsAsianViewBetActive, isAVResponsibleGamblingError } from 'utils/asianView';

import {
  AsianViewBetSlipValidationMessage,
  AsianViewBetStatusResponse,
  AsianViewPlacedBetsByMarket,
  TAsianPlaceBet,
  TAsianSelectedBet,
  TAsianSelectedBets,
  TAsianUpdateBetPayload,
  TAsianValidationMessagePayload,
  TAsianViewBetslipState,
  TPlacedBetException
} from './type';

const initialState: TAsianViewBetslipState = {
  selectedTab: EAsianBetslipTabs.BET_SLIP,
  selectedBets: {},
  placeBetsState: EPlaceBetsStates.SELECT,
  placedBetsByMarket: {},
  placeBetsLoading: false,
  statuses: {},
  statusesError: null,
  statusesLoading: false,
  statusesOfferIds: [],
  areStatusesLoaded: false,
  isAtLeastOnePendingStatus: false,
  isSelectedBetsLimitNotification: false,
  liabilityByMarket: {},
  rgErrorMessage: null
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW_BETSLIP,
  initialState,
  reducers: {
    setSelectedTab: (state, { payload }: PayloadAction<EAsianBetslipTabs>) => {
      state.selectedTab = payload;
    },
    setSelectedBet: (state, { payload }: PayloadAction<TAsianSelectedBet>) => {
      const selectedBetsStorageString = localStorage.getItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
      const selectedBetsFromStorage = selectedBetsStorageString ? JSON.parse(selectedBetsStorageString) : {};
      const selectedBetsAmount = Object.keys(state.selectedBets).length;
      const existedBet = Object.values(state.selectedBets).find(({ state: betState, identifier: betIdentifier }) => {
        return payload.identifier === betIdentifier && betState === AsianViewSelectedBetStates.selected;
      });

      if (existedBet) {
        delete state.selectedBets[existedBet.fullIdentifier];
        delete selectedBetsFromStorage[existedBet.fullIdentifier];

        const offerId = state.placedBetsByMarket[payload.marketId]?.offerIds?.[payload.fullIdentifier];

        if (offerId) {
          delete state.statuses[offerId];
        }

        state.isSelectedBetsLimitNotification = false;
        localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify(selectedBetsFromStorage));
      } else {
        if (selectedBetsAmount >= ASIAN_SELECTED_BETS_AMOUNT_LIMIT) {
          state.isSelectedBetsLimitNotification = true;
        } else {
          const newSelectedBet = {
            ...payload,
            initPrice: payload.price,
            order: payload.dateTime
          };

          const newStorageSelectedBets = {
            ...selectedBetsFromStorage,
            [payload.fullIdentifier]: newSelectedBet
          };

          localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify(newStorageSelectedBets));

          state.selectedBets[payload.fullIdentifier] = newSelectedBet;
          state.selectedTab = EAsianBetslipTabs.BET_SLIP;
        }
      }
    },
    updateAVSelectedBet: (state, { payload }: PayloadAction<TAsianUpdateBetPayload>) => {
      if (payload.identifier && state.selectedBets[payload.identifier]) {
        state.selectedBets[payload.identifier] = { ...state.selectedBets[payload.identifier], ...payload.data };

        if (payload.updateLocalStorageBets) {
          const selectedBetsStorageString = localStorage.getItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
          const selectedBetsFromStorage = selectedBetsStorageString ? JSON.parse(selectedBetsStorageString) : {};

          localStorage.setItem(
            ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME,
            JSON.stringify({
              ...selectedBetsFromStorage,
              [payload.identifier]: { ...selectedBetsFromStorage[payload.identifier], ...payload.data }
            })
          );
        }
      }
    },
    removeAVSelectedBets: (state, { payload }: PayloadAction<(string | undefined)[]>) => {
      const selectedBetsStorageString = localStorage.getItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
      const selectedBetsFromStorage = selectedBetsStorageString ? JSON.parse(selectedBetsStorageString) : {};

      payload.forEach(key => {
        if (key && state.selectedBets[key]) {
          const bet = state.selectedBets[key];

          delete state.selectedBets[key];
          delete selectedBetsFromStorage[key];

          const offerId = state.placedBetsByMarket[bet.marketId]?.offerIds?.[bet.fullIdentifier];

          if (offerId) {
            delete state.statuses[offerId];
          }
        }
      });

      state.isSelectedBetsLimitNotification = false;

      localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify(selectedBetsFromStorage));
    },
    setAVBetSlipValidationMessage: (state, { payload }: PayloadAction<TAsianValidationMessagePayload>) => {
      if (payload.identifier && state.selectedBets[payload.identifier]) {
        state.selectedBets[payload.identifier].validationMessage = payload.message;

        if (!isUndefined(payload.sizeValidationType)) {
          state.selectedBets[payload.identifier].sizeValidationType = payload.sizeValidationType;
        }

        if (!isUndefined(payload.messageId)) {
          state.selectedBets[payload.identifier].validationMessageId = payload.messageId;
          if (payload.messageId == ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX026) {
            state.selectedBets[payload.identifier].placementAttempt =
              (state.selectedBets[payload.identifier].placementAttempt || DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS) + 1;
          }
        }
      }
    },
    setPlaceBetsState: (state, action) => {
      state.placeBetsState = action.payload;
    },
    placeAVActiveSelectedBets: (
      state,
      _: PayloadAction<{
        data: { [key: string]: TAsianPlaceBet[] };
        onSuccessCallback: (offerIds: number[]) => void;
        onErrorCallback: () => void;
      }>
    ) => {
      state.placeBetsLoading = true;

      keys(state.selectedBets)
        .filter(identifier => getIsAsianViewBetActive({ bet: state.selectedBets[identifier] }))
        .forEach(identifier => {
          const bet = state.selectedBets[identifier];

          delete bet.betStatus;
          delete bet.betStatusError;
          delete bet.offerId;

          if (!bet.offerId) {
            bet.bettingAction = EAsianBettingActions.PROGRESS;
            bet.state = AsianViewSelectedBetStates.placing;
          }
        });
    },
    successAVPlaceActiveSelectedBets: (state, { payload }: PayloadAction<AsianViewPlacedBetsByMarket>) => {
      const arePendingStatuses = Object.values(state.statuses).some(
        statusData => statusData.status === BetsStatusesTypes.PENDING || isEmpty(statusData)
      );

      if (arePendingStatuses) {
        state.placedBetsByMarket = mergeWith({}, state.placedBetsByMarket, payload, (objValue, srcValue, key) => {
          if (key === 'offerIds' && isObject(objValue) && isObject(srcValue)) {
            return { ...objValue, ...srcValue };
          }

          return undefined;
        });
      } else {
        state.placedBetsByMarket = payload;
      }

      state.placeBetsLoading = false;

      entries(payload).forEach(([marketId, { status, exception, offerIds }]) => {
        if (status === 'FAIL' && exception) {
          const failedSelectedBets = keys(state.selectedBets).filter(
            identifier =>
              getIsAsianViewBetActive({ bet: state.selectedBets[identifier], ignoreBettingAction: true }) &&
              identifier.startsWith(marketId)
          );

          if (isAVResponsibleGamblingError(exception)) {
            state.rgErrorMessage = exception;
          }

          failedSelectedBets.forEach(identifier => {
            if (exception.id == ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX026) {
              state.selectedBets[identifier].placementAttempt =
                (state.selectedBets[identifier].placementAttempt || DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS) + 1;
            }

            state.selectedBets[identifier].validationMessage = exception.message;
            state.selectedBets[identifier].validationMessageId = exception.id;
            state.selectedBets[identifier].state = AsianViewSelectedBetStates.unplaced;
            delete state.selectedBets[identifier].bettingAction;
          });
        } else if (status === 'OK' && offerIds) {
          Object.entries(offerIds).forEach(([identifier, offerId]) => {
            state.selectedBets[identifier].offerId = offerId;
            state.selectedBets[identifier].state = AsianViewSelectedBetStates.placed;
          });

          localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify(state.selectedBets));
        }
      });
    },
    failureAVPlaceActiveSelectedBets: state => {
      state.placeBetsLoading = false;
    },
    removeAllSelectedBets: state => {
      state.selectedBets = {};
      state.statuses = {};

      localStorage.removeItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
    },
    setSelectedBets: (state, { payload }: PayloadAction<TAsianSelectedBets>) => {
      if (!keys(state.selectedBets).length || JSON.stringify(payload) !== JSON.stringify(state.selectedBets)) {
        state.selectedBets = payload;
      }
    },
    setStatusesOfferIds: (state, { payload }: PayloadAction<number[]>) => {
      state.statusesOfferIds = union(payload, [...state.statusesOfferIds]);
    },
    fetchAsianViewBetStatuses: (
      state,
      _: PayloadAction<{ offerIds: number[]; onErrorCallback: () => void; onSuccessCallback?: () => void }>
    ) => {
      state.statusesLoading = true;
    },
    successFetchAsianViewBetsStatuses: (state, { payload }: PayloadAction<AsianViewBetStatusResponse>) => {
      const notPendingOfferIds: number[] = [];
      entries(payload).forEach(([offerId, status]) => {
        if (!state.statuses[offerId]) {
          state.statuses[offerId] = {};
        }

        if (status.state) {
          state.statuses[offerId].status = status.state;
        } else if (status.exception) {
          state.statuses[offerId].error = status.exception;
        }

        if (status.state !== BetsStatusesTypes.PENDING) {
          notPendingOfferIds.push(+offerId);
        }
      });

      if (!state.areStatusesLoaded) {
        state.areStatusesLoaded = true;
      }

      state.statusesOfferIds = [...state.statusesOfferIds].filter(offerId => !notPendingOfferIds?.includes(offerId));
      state.statusesLoading = false;
      state.isAtLeastOnePendingStatus = values(payload).some(status => status.state === BetsStatusesTypes.PENDING);
      keys(state.selectedBets).forEach(identifier => {
        const offerId =
          state.placedBetsByMarket[state.selectedBets[identifier].marketId]?.offerIds?.[
            state.selectedBets[identifier].fullIdentifier
          ];

        if (offerId) {
          if (state.statuses[offerId].error || state.statuses[offerId].status !== BetsStatusesTypes.PENDING) {
            delete state.selectedBets[identifier].bettingAction;
          }

          if (state.statuses[offerId].status) {
            state.selectedBets[identifier].betStatus = state.statuses[offerId].status;
          }

          if (state.statuses[offerId].error) {
            state.selectedBets[identifier].betStatusError = state.statuses[offerId].error;
          }

          if (
            state.statuses[offerId].status !== BetsStatusesTypes.EXPIRED &&
            state.statuses[offerId].status !== BetsStatusesTypes.CANCELLED
          ) {
            state.selectedBets[identifier].offerId = offerId;
          }
        }
      });
      localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify({ ...state.selectedBets }));
    },
    failureFetchAsianViewBetStatuses: (
      state,
      { payload }: PayloadAction<{ offersIds: number[]; error: TPlacedBetException }>
    ) => {
      const notPendingOfferIds: number[] = [];
      payload.offersIds.forEach(offerId => {
        if (!state.statuses[offerId]) {
          state.statuses[offerId] = {};
        }
        notPendingOfferIds.push(offerId);

        state.statuses[offerId].error = payload.error;
        delete state.statuses[offerId].status;
      });

      state.statusesOfferIds = [...state.statusesOfferIds].filter(offerId => !notPendingOfferIds?.includes(offerId));
      state.statusesLoading = false;
      state.isAtLeastOnePendingStatus = values(state.statuses).some(
        ({ status }) => status === BetsStatusesTypes.PENDING
      );
    },
    removeBetStatus: (state, { payload }: PayloadAction<number>) => {
      if (state.statuses[payload]?.error) {
        delete state.statuses[payload];
      }
    },
    setBetSlipValidationMessageForActiveSelectedBets: (
      state,
      { payload }: PayloadAction<AsianViewBetSlipValidationMessage>
    ) => {
      keys(state.selectedBets)
        .filter(identifier => getIsAsianViewBetActive({ bet: state.selectedBets[identifier] }))
        .forEach(identifier => {
          state.selectedBets[identifier].validationMessage = payload.message;
          state.selectedBets[identifier].sizeValidationType = payload.sizeValidationType;
          state.selectedBets[identifier].validationMessageId = payload.messageId;
        });
    },
    setIsSelectedBetsLimitNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelectedBetsLimitNotification = payload;
    },
    setLiabilityByMarket: (state, { payload }: PayloadAction<{ marketId: string; liability: number }>) => {
      state.liabilityByMarket[payload.marketId] = payload.liability;
    },
    setBetslipRGErrorMessage: (state, { payload }: PayloadAction<TPlacedBetException | null>) => {
      state.rgErrorMessage = payload;
    }
  }
});

export const {
  removeAVSelectedBets,
  setSelectedBet,
  setSelectedTab,
  setAVBetSlipValidationMessage,
  updateAVSelectedBet,
  setPlaceBetsState,
  placeAVActiveSelectedBets,
  successAVPlaceActiveSelectedBets,
  failureAVPlaceActiveSelectedBets,
  removeAllSelectedBets,
  setSelectedBets,
  setStatusesOfferIds,
  fetchAsianViewBetStatuses,
  successFetchAsianViewBetsStatuses,
  failureFetchAsianViewBetStatuses,
  setBetSlipValidationMessageForActiveSelectedBets,
  setIsSelectedBetsLimitNotification,
  removeBetStatus,
  setLiabilityByMarket,
  setBetslipRGErrorMessage
} = slice.actions;

export const setActiveSelectedBetsFromStorage = createAsyncThunk(
  `${SLICES_NAMES.ASIAN_VIEW_BETSLIP}/CHECK_IF_MARKETS_ARE_CLOSED`,
  async (selectedBetsFromStorage: TAsianSelectedBets, { dispatch, rejectWithValue }) => {
    try {
      const marketsIds = values(selectedBetsFromStorage).map(({ marketId }) => marketId);

      const marketsStatuses: Record<string, boolean> = await api.checkMarkets(marketsIds);

      const selectedBets = entries(selectedBetsFromStorage)
        .filter(([, { marketId }]) => marketsStatuses[marketId])
        .reduce((acc, [identifier, bet]) => {
          return {
            ...acc,
            [identifier]: bet
          };
        }, {});

      dispatch(setSelectedBets(selectedBets));
      localStorage.setItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME, JSON.stringify(selectedBets));
    } catch (err: any) {
      rejectWithValue(err);
    }
  }
);

export default slice.reducer;
