import { ButtonHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';

import styles from './styles.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary';
  className?: string;
  isSelected?: boolean;
}

const Button = ({ children, className, isSelected, variant = 'primary', ...props }: ButtonProps): JSX.Element => {
  return (
    <button
      className={classNames(styles.button, className, {
        [`${componentsBranding.PRIMARY_BTN} ${styles.button__primary}`]: variant === 'primary',
        [`${componentsBranding.SECONDARY_BTN} ${styles.button__secondary}`]: variant === 'secondary',
        [`${componentsBranding.TERTIARY_BTN} ${styles.button__tertiary}`]: variant === 'tertiary',
        [styles.button__selected]: isSelected
      })}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
