import { useSelector } from 'react-redux';

import {
  getAsianQuickPlacedBetsProgressList,
  getAsianQuickPlacedBetsWithoutOffer
} from 'redux/modules/asianViewQuickBetting/selectors';

import ProgressQuickBet from '../ProgressQuickBet';
import ProgressQuickBetWithoutOffer from '../ProgressQuickBetWithoutOffer';

const ProgressQuickBetList = () => {
  const progressQuickBetsList = useSelector(getAsianQuickPlacedBetsProgressList);
  const progressQuickBetsWithoutOffer = useSelector(getAsianQuickPlacedBetsWithoutOffer);

  return (
    <>
      {progressQuickBetsList.map((quickBet, index) => (
        <ProgressQuickBet quickBet={quickBet} key={quickBet.identifier + '_' + (quickBet.updateTime || index)} />
      ))}
      {progressQuickBetsWithoutOffer.map((quickBet, index) => (
        <ProgressQuickBetWithoutOffer
          quickBet={quickBet}
          key={quickBet.identifier + '_' + (quickBet.updateTime || index)}
        />
      ))}
    </>
  );
};

export default ProgressQuickBetList;
