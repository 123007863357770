import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toNumber } from 'lodash';

import { getAsianCurrentBetByOfferId } from 'redux/modules/asianViewCurrentBets/selectors';
import { updatePlacedQuickBet } from 'redux/modules/asianViewQuickBetting';
import { TAsianQuickPlacedBet } from 'redux/modules/asianViewQuickBetting/type';

interface IProgressQuickBetWithoutOffer {
  quickBet: TAsianQuickPlacedBet;
}
const ProgressQuickBetWithoutOffer = ({ quickBet }: IProgressQuickBetWithoutOffer) => {
  const dispatch = useDispatch();
  const currentBet = useSelector(getAsianCurrentBetByOfferId(toNumber(quickBet?.offerId || 0)));

  useEffect(() => {
    if (currentBet && !quickBet.offer) {
      dispatch(
        updatePlacedQuickBet({
          ...quickBet,
          offer: currentBet,
          isHidden: false,
          noAnimation: false,
          updateTime: quickBet.isHidden ? quickBet.updateTime : new Date().getTime()
        })
      );
    }
  }, [!!currentBet]);

  return null;
};

export default ProgressQuickBetWithoutOffer;
