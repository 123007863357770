import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MobileMessage from 'components/AsianViewMobileBetslip/components/MobileSelectedBet/components/Message';
import AVChangedOddsNotification from 'components/AVChangedOddsNotification/AVChangedOddsNotification';
import { asianViewBetslipBranding } from 'constants/branding';
import useOddsDisplayFormat from 'hooks/useOddsDisplayFormat';
import { AsianViewMobileBetActions, TAsianMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip/type';
import { getMarketPricesStatusById } from 'redux/modules/marketsPrices/selectors';
import { getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';
import { MarketStatus } from 'types';
import { BetTypes } from 'types/bets';

export interface IMobileMessages {
  bet: TAsianMobileSelectedBet;
  onClose: () => void;
}

const MobileMessages = ({ bet, onClose }: IMobileMessages) => {
  const { t } = useTranslation();

  const userAutoRefresh = useSelector(getUserAsianViewAutoRefresh);
  const status = useSelector(getMarketPricesStatusById(bet.marketId));
  const odds = useOddsDisplayFormat(bet);

  const isSuspended = status === MarketStatus.SUSPENDED;
  const isDisabledBet = !odds || isSuspended;
  const isValidation = bet.action === AsianViewMobileBetActions.VALIDATION;
  const isProgress = bet.action === AsianViewMobileBetActions.PROGRESS;
  const isConfirm = bet.action === AsianViewMobileBetActions.CONFIRM;
  const isOddsUpdated = userAutoRefresh && !isSuspended && !isDisabledBet && !isProgress && odds !== bet.initPrice;
  const isBack = bet.betType === BetTypes.BACK;
  const isPrevOddsLower = !!(bet.initPrice && +bet.initPrice < (odds || 0));
  const isGoingUp = isBack ? isPrevOddsLower : !isPrevOddsLower;

  const closeMessageHandler = () => {
    if (bet.betslipId) {
      onClose();
    }
  };

  return (
    <>
      {isValidation && bet.validationMessage && (
        <MobileMessage
          type="error"
          className={asianViewBetslipBranding.ERROR_MSG}
          message={bet.validationMessage}
          onClose={closeMessageHandler}
          onClickOutside={closeMessageHandler}
        />
      )}
      {isOddsUpdated && !isConfirm && (
        <MobileMessage
          type="info"
          className={asianViewBetslipBranding.ODDS_CHANGED_MSG}
          message={<AVChangedOddsNotification isGoingUp={isGoingUp} odds={odds} initPrice={bet.initPrice} />}
        />
      )}
      {isDisabledBet && !isProgress && (
        <MobileMessage
          type="info"
          className={asianViewBetslipBranding.INFO_MSG}
          message={t('asianView.labels.selectedBet.unavailable')}
        />
      )}
    </>
  );
};

export default MobileMessages;
