import { useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { QUICK_BETTING_BOTTOM_PADDING, QUICK_BETTING_LEFT_RIGHT_PADDING } from 'constants/asianView';
import { asianViewBetslipBranding as branding } from 'constants/branding';
import useWindowSize from 'hooks/useWindowSize';
import { getAsianSectionSize } from 'redux/modules/asianView/selectors';
import { getAsianQuickBettingItem } from 'redux/modules/asianViewQuickBetting/selectors';
import { EAsianBettingActions } from 'redux/modules/asianViewQuickBetting/type';
import {
  getBetOdds,
  getMarketPricesRunnerLockedBySelectionId,
  getMarketPricesStatusById
} from 'redux/modules/marketsPrices/selectors';
import { MarketStatus } from 'types';
import { BetDatabaseName, BetTypes } from 'types/bets';

import QuickBettingActions from './components/QuickBettingActions';
import QuickBettingForm from './components/QuickBettingForm';
import QuickBettingProgress from './components/QuickBettingProgress';

import styles from './styles.module.scss';

type QuickBettingProps = {
  betIdentifier: string;
};

const QuickBetting = ({ betIdentifier }: QuickBettingProps) => {
  const bet = useSelector(getAsianQuickBettingItem(betIdentifier));
  const sectionSize = useSelector(getAsianSectionSize);
  const status = useSelector(getMarketPricesStatusById(bet.marketId));
  const isSuspended = status === MarketStatus.SUSPENDED;
  const isBack = bet.betType === BetTypes.BACK;
  const betName = isBack ? BetDatabaseName.BACK : BetDatabaseName.LAY;
  const betOdds = useSelector(getBetOdds(bet.marketId, bet?.selectionId ?? 0, +(bet?.handicap ?? 0), betName, 0));
  const isLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(bet.marketId, bet.selectionId, +(bet.handicap ?? 0))
  );

  const isDisabledBet = !betOdds || isSuspended || isLocked;
  const { windowHeight } = useWindowSize();
  const elementRef = useRef<HTMLDivElement>(null);

  const left = elementRef.current?.getBoundingClientRect().left ?? 0;
  const y = elementRef.current?.getBoundingClientRect().y ?? 0;
  const isLeft = left - (sectionSize?.left ?? 0) < QUICK_BETTING_LEFT_RIGHT_PADDING;
  const isRight = (sectionSize?.left ?? 0) + (sectionSize?.width ?? 0) - left < QUICK_BETTING_LEFT_RIGHT_PADDING;
  const isBottom = windowHeight - (y ?? 0) < QUICK_BETTING_BOTTOM_PADDING;

  const isProgress = bet.action === EAsianBettingActions.PROGRESS;
  const isHidden = bet.action === EAsianBettingActions.HIDDEN;

  const isComponentHidden = isProgress || isHidden || !left || !windowHeight;

  return (
    <div className={classNames(styles.quickBet__wrap, branding.QUICK_BETTING)} ref={elementRef}>
      <div
        className={classNames(styles.quickBet, branding.SELECTED_BET, {
          [styles.quickBet__back]: isBack && !isDisabledBet,
          [styles.quickBet__lay]: !isBack && !isDisabledBet,
          [styles.quickBet__disabled]: isDisabledBet,
          [styles.quickBet__bottom]: isBottom,
          [styles.quickBet__left]: isLeft,
          [styles.quickBet__right]: isRight,
          [styles.quickBet__hidden]: isComponentHidden,
          [branding.BACK_BET]: isBack && !isDisabledBet,
          [branding.LAY_BET]: !isBack && !isDisabledBet,
          [branding.LOCKED_BET]: isDisabledBet
        })}
      >
        {!isComponentHidden && <QuickBettingForm bet={bet} />}
        {!isProgress && !isDisabledBet && <QuickBettingActions bet={bet} />}
        {isProgress && <QuickBettingProgress />}
      </div>
    </div>
  );
};

export default QuickBetting;
