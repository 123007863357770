import classNames from 'classnames';

import { TAsianQuickPlacedBet } from 'redux/modules/asianViewQuickBetting/type';

import Notification from './Notification';

import styles from './styles.module.scss';

interface IQuickBettingNotificationsProps {
  quickBets: TAsianQuickPlacedBet[];
  hasSelectedBets: boolean;
}
const QuickBettingNotifications = ({ quickBets, hasSelectedBets }: IQuickBettingNotificationsProps) => {
  if (quickBets.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(styles.notifications, {
        [styles.notifications__border]: hasSelectedBets
      })}
    >
      {quickBets.map(quickBet => (
        <Notification key={quickBet.updateTime} quickBet={quickBet} />
      ))}
    </div>
  );
};

export default QuickBettingNotifications;
