import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { asianViewMiddleSection as branding } from 'constants/branding';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import { TAsianViewRunner } from 'redux/modules/asianView/type';
import { removeMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip';
import {
  getIsRunnerPricesByRunnerIdAndHandicap,
  getMarketPricesCurrencyById,
  getMarketPricesStatusById
} from 'redux/modules/marketsPrices/selectors';
import { BetTypes } from 'types/bets';

import OddsCell from '../OddsCell';
import SelectionOddsSkeleton from '../SelectionOddsSkeleton';

import styles from './styles.module.scss';

interface SelectionOddsProps {
  competitionId: string;
  eventId: string;
  marketId: string;
  name?: string;
  runner: TAsianViewRunner;
  isSingleLine?: boolean;
  hasSkeleton?: boolean;
  oddsLabel?: string;
  betslipId?: string;
  runnerIndex?: number;
}

const SelectionOdds = ({
  competitionId,
  eventId,
  marketId,
  name,
  runner,
  isSingleLine = false,
  hasSkeleton = false,
  oddsLabel = '',
  betslipId,
  runnerIndex
}: SelectionOddsProps) => {
  const dispatch = useDispatch();
  const isLayBetting = useAsianLayBetting();

  const isRunnerPrices = useSelector(getIsRunnerPricesByRunnerIdAndHandicap(marketId, runner.id, runner.handicap));
  const currency = useSelector(getMarketPricesCurrencyById(marketId));
  const status = useSelector(getMarketPricesStatusById(marketId));

  useEffect(() => {
    return () => {
      dispatch(removeMobileSelectedBet({ betslipId }));
    };
  }, []);

  if (!isRunnerPrices) {
    return hasSkeleton ? <SelectionOddsSkeleton isMobileCell /> : null;
  }

  return (
    <div
      className={classNames(
        'biab_asian-view-market-selection',
        styles.marketCell__selection,
        branding.MAIN_CONTAINER_BORDER
      )}
    >
      {name && <span className={classNames('biab_asian-view-market-name', styles.marketCell__odds__name)}>{name}</span>}
      {!isLayBetting && isSingleLine && <div className={classNames(styles.marketCell__odds__space)} />}
      <OddsCell
        competitionId={competitionId}
        eventId={eventId}
        marketId={marketId}
        selectionId={runner.id}
        handicap={runner.handicap}
        isSingleLine={isSingleLine}
        marketStatus={status}
        marketCurrency={currency}
        betType={BetTypes.BACK}
        oddsLabel={oddsLabel}
        betslipId={betslipId}
        runnerIndex={runnerIndex}
      />
      {isLayBetting && (
        <OddsCell
          competitionId={competitionId}
          eventId={eventId}
          marketId={marketId}
          selectionId={runner.id}
          handicap={runner.handicap}
          isSingleLine={isSingleLine}
          marketStatus={status}
          marketCurrency={currency}
          betType={BetTypes.LAY}
          oddsLabel={oddsLabel}
          betslipId={betslipId}
          runnerIndex={runnerIndex}
        />
      )}
    </div>
  );
};

export default SelectionOdds;
