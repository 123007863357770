import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getAsianSelectedBetsList, getPlaceBetsState } from 'redux/modules/asianViewBetslip/selectors';
import { EPlaceBetsStates } from 'redux/modules/betslip/type';
import { getIsAsianViewBetActive } from 'utils/asianView';

import AVSelectedBet from '../AVSelectedBet';

const AVSelectedBets = () => {
  const selectedBets = useSelector(getAsianSelectedBetsList);
  const placeBetsState = useSelector(getPlaceBetsState);

  if (placeBetsState === EPlaceBetsStates.CONFIRM) {
    return <AVConfirmationSelectedBets />;
  }

  return (
    <>
      {selectedBets.map((bet, index) => (
        <AVSelectedBet key={bet.fullIdentifier} bet={bet} index={index} />
      ))}
    </>
  );
};

const AVConfirmationSelectedBets = () => {
  const selectedBets = useSelector(getAsianSelectedBetsList);
  const [bets, setBets] = useState(selectedBets);

  useEffect(() => {
    setBets(currentBets => currentBets.filter(bet => getIsAsianViewBetActive({ bet })));
  }, []);

  return (
    <>
      {bets.map((bet, idx) => (
        <AVSelectedBet key={bet.fullIdentifier} bet={bet} index={idx} />
      ))}
    </>
  );
};

export default AVSelectedBets;
