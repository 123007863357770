import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isUndefined, toNumber } from 'lodash';

import Notification from 'components/Notification';
import {
  BETSLIP_LINE_INT_ODDS,
  HIDDEN_PARTIAL_MATCHED_NOTIFICATIONS_LOCAL_STORAGE_NAME,
  tabulation
} from 'constants/betslip';
import { betslipBranding, betslipBranding as branding } from 'constants/branding';
import { GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import useBetMessages from 'hooks/useBetMessages';
import useBetslipOpenBetsSorting from 'hooks/useBetslipOpenBetsSorting';
import useConvertTimeToString from 'hooks/useConvertTimeToString';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useLayColumn from 'hooks/useLayColumn';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import {
  removeBetSlipUnmatchedOfferError,
  removePlacedBet,
  setFirstOpenedBetFocused,
  setPlacedBetsToUpdate
} from 'redux/modules/betslip';
import {
  getBetSlipUnmatchedOfferErrorById,
  getIsFirstOpenedBetFocused,
  getIsGameBetSlip,
  getPlacedBets
} from 'redux/modules/betslip/selectors';
import { EBetFocusFields, EPlaceBetsStates, TBetslipFormProps } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { cleanCashOutPlacedId } from 'redux/modules/cashOut';
import { getCashOutPlacedId } from 'redux/modules/cashOut/selectors';
import { setCurrentBetAction, setCurrentBetCanBeRemoved, updateOfferPlacementData } from 'redux/modules/currentBets';
import { ECurrentBetActions, TCurrentBet } from 'redux/modules/currentBets/type';
import { getCurrentGameIsLayOddsDisabled, getCurrentGameRound } from 'redux/modules/games/selectors';
import {
  getIsMarketClosed,
  getIsMarketSuspended,
  getMarketPricesIsSelectionNonRunner,
  getMarketPricesRunnerLockedBySelectionId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { getAccountSettings, getUsername } from 'redux/modules/user/selectors';
import { SportId } from 'types';
import { BetTypes, MatchTypes } from 'types/bets';
import { BetMessageType, EPlacementType } from 'types/betslip';
import { BettingType } from 'types/markets';
import {
  getCurrentBetPrice,
  getCurrentBetProfit,
  getCurrentBetSize,
  getIsFullyMatched,
  getIsPartiallyMatched,
  isCancelled,
  isEachWayMarketType
} from 'utils/betslip';
import { calculateLiability, calculateSize } from 'utils/liability';

import BetForm from '../BetForm';
import BetLabels from '../BetLabels';

import BetActions from './components/BetActions';
import BetInfo from './components/BetInfo';
import BetMessage from './components/BetMessage';
import BetPersistenceType from './components/BetPersistenceType';
import BetPLButtonAndCashOut from './components/BetPLButtonAndCashOut/BetPLButtonAndCashOut';
import UnmatchedBetProgressBar from './components/UnmatchedBetProgressBar/UnmatchedBetProgressBar';

import styles from './styles.module.scss';

type OpenedBetProps = {
  bet: TCurrentBet;
  matchType: MatchTypes;
  betIndex: number;
  isFirst: boolean;
  isLast: boolean;
  side: BetTypes;
  updatedBet?: TCurrentBet;
};

const OpenedBet = ({ bet, matchType, betIndex, isFirst, isLast, updatedBet }: OpenedBetProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const placedBets = useSelector(getPlacedBets);
  const isFirstOpenedBetFocused = useSelector(getIsFirstOpenedBetFocused);
  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const accountSettings = useSelector(getAccountSettings);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const gameDataRound = useSelector(getCurrentGameRound);
  const cashOutPlacedId = useSelector(getCashOutPlacedId(bet.marketId));
  const isMarketClosed = useSelector(getIsMarketClosed(bet.marketId));
  const isSelectionLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(bet.marketId, bet.selectionId ?? 0, +(bet.handicap ?? 0))
  );
  const isNonRunner = useSelector(
    getMarketPricesIsSelectionNonRunner(bet.marketId, bet.selectionId ?? 0, +(bet.handicap ?? 0))
  );
  const isSuspended = useSelector(getIsMarketSuspended(bet.marketId));
  const unmatchedBetError = useSelector(
    getBetSlipUnmatchedOfferErrorById(matchType === MatchTypes.UNMATCHED ? bet.offerId : undefined)
  );
  const isGamesLayOddsDisabled = useSelector(
    getCurrentGameIsLayOddsDisabled(isGameBetSlip && bet.side === BetTypes.LAY)
  );
  const username = useSelector(getUsername);

  const [updatedPersistenceType, setUpdatedPersistenceType] = useState(bet.persistenceType);
  const [messageType, setMessageType] = useState<BetMessageType>(null);
  const [isLongPlacingMessage, setIsLongPlacingMessage] = useState(false);

  const prevIsUnmatchedBetDisabled = useRef(false);

  const { isLayColumnEnabled } = useLayColumn({
    sportId:
      matchType === MatchTypes.UNMATCHED && !isGameBetSlip && bet.side === BetTypes.LAY
        ? String(bet.eventTypeId)
        : undefined,
    bettingType:
      matchType === MatchTypes.UNMATCHED && !isGameBetSlip && bet.side === BetTypes.LAY ? bet.bettingType : undefined
  });

  const { isSortedByEvents } = useBetslipOpenBetsSorting();

  const isLayOddsDisabled = isGameBetSlip ? isGamesLayOddsDisabled : !isLayColumnEnabled;
  const isUnmatchedBetDisabled =
    matchType === MatchTypes.UNMATCHED &&
    (isSuspended ||
      isSelectionLocked ||
      (bet.side === BetTypes.LAY && bet.disabledLayOdds) ||
      isNonRunner ||
      isLayOddsDisabled);
  let isFullyMatched = false;
  if (matchType === MatchTypes.UNMATCHED) {
    isFullyMatched = (!!updatedBet && getIsFullyMatched(updatedBet)) || getIsFullyMatched(bet);
  }
  const { betMessage, betslipMessage } = useBetMessages({
    bet,
    isUnmatched: matchType === MatchTypes.UNMATCHED,
    isUnmatchedBetDisabled,
    isFullyMatched
  });
  const { fillOrKill, placeBetWithEnterKey, swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const { hoursAndMinutes } = useConvertTimeToString({ startDate: toNumber(bet.marketStartDate || 0) });
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: bet?.marketId,
    eventId: bet?.eventId,
    observerOptions: { rootMargin: '200px' },
    section: MarketsPricesSocketParamsSections.SportsDesktopBetSlip
  });
  const isPlaceBetsWithEnterKeyEnabled = placeBetWithEnterKey && accountSettings?.placeBetWithEnterKey;
  const isFillOrKillEnabled = fillOrKill && accountSettings?.fillOrKillEnabled;
  const placedBetStatus = placedBets[bet.offerId];
  const isEachWay = isEachWayMarketType(bet.marketType);
  const startTimeFormatted = bet.marketStartDate ? hoursAndMinutes : '';
  const isEventLinkEnabled = !!bet.eventId && bet.marketType !== MARKET_TYPES.dailyGoals && !isGameBetSlip;
  const lowerCaseMatchType = matchType.toLowerCase();
  const lineRangeInfo = useMemo(
    () => ({
      marketUnit: bet.marketUnit || '',
      minUnitValue: bet.minUnitValue,
      maxUnitValue: bet.maxUnitValue,
      interval: bet.interval
    }),
    [bet]
  );
  const updatedPrice =
    isFullyMatched && updatedBet ? updatedBet.averagePriceRounded || updatedBet.price : getCurrentBetPrice(bet);
  const updatedSize = getCurrentBetSize(isFullyMatched ? MatchTypes.MATCHED : matchType, bet);
  const updatedProfit = getCurrentBetProfit(isFullyMatched ? MatchTypes.MATCHED : matchType, bet);
  const isSizeValidValue = !isUndefined(bet.isSizeValid) ? bet.isSizeValid : true;
  const isPriceValidValue = !isUndefined(bet.isPriceValid) ? bet.isPriceValid : true;
  const isUpdateEnabled =
    (isPriceValidValue && bet.price !== toNumber(bet.changedPrice) && !isUndefined(bet.changedPrice)) ||
    (isSizeValidValue && bet.sizeRemaining !== toNumber(bet.changedSize) && !isUndefined(bet.changedSize)) ||
    bet.persistenceType !== updatedPersistenceType;
  const eventLink = isGameBetSlip
    ? `${GAMES_BASE_URL}/${bet.eventTypeId}`
    : `${SPORT_BASE_URL}/${bet.eventTypeId}/event/${bet.eventId}`;
  const isMatchedType = matchType === MatchTypes.MATCHED;
  const marketLink = isGameBetSlip
    ? `${GAMES_BASE_URL}/${bet.eventTypeId}`
    : `${SPORT_BASE_URL}/${bet.eventTypeId}/market/${bet.marketId}`;
  const isLapsed = bet.offerState === BetsStatusesTypes.LAPSED;
  let fullMarketName = isSortedByEvents && bet.marketNameWithParents ? bet.marketNameWithParents : bet.marketName;
  let focusedField: EBetFocusFields | null = null;
  let progressBarLabel: string | null = null;

  if (bet.raceName) {
    fullMarketName = `${startTimeFormatted} ${bet.raceName} - ${bet.marketName}`;
  } else if (isGameBetSlip && bet.sportName) {
    fullMarketName = bet.sportName;
  }

  if (
    bet.action === ECurrentBetActions.CANCELLING &&
    bet.offerState !== BetsStatusesTypes.CANCELLED &&
    +bet.sizeCancelled === 0 &&
    +bet.sizeRemaining > 0
  ) {
    progressBarLabel = t('betslip.labels.cancellingBet');
  } else if (bet.action === ECurrentBetActions.EDITING) {
    progressBarLabel = t('betslip.labels.placingBet');
  }

  if (matchType === MatchTypes.UNMATCHED && isFirst && isFirstOpenedBetFocused) {
    focusedField = !isPNCEnabled || isGameBetSlip ? EBetFocusFields.PRICE : EBetFocusFields.SIZE;
  }

  const isCricket = bet.eventTypeId.toString() === SportId.CRICKET;
  const isLineMarket = bet.bettingType === BettingType.LINE;
  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;

  const getContentAfterBetForm = () => {
    if (isMatchedType) {
      if (isGameBetSlip) {
        return null;
      }

      const marketLinkEl = (
        <Link className={classNames(styles.marketName, branding.OPENED_BET_HEADER, branding[bet.side])} to={marketLink}>
          {fullMarketName}
        </Link>
      );

      if (bet.isCombined && matchType === MatchTypes.MATCHED) {
        return (
          <div className={styles.openedBet__marketLinkWithButtons}>
            {marketLinkEl}
            <div className={styles.openedBet__plBtnAndCashOut}>
              {bet.combinedAmount && bet.combinedAmount > 1 && (
                <span className={styles.openedBet__consolidatedLabel}>
                  {t('betslip.labels.betsConsolidated', { N: bet.combinedAmount })}
                </span>
              )}
              {(bet.isPNLAvailable || bet.triggeredByCashOut) && (
                <BetPLButtonAndCashOut matchType={matchType} bet={bet} hidePLButton={isSortedByEvents} />
              )}
            </div>
          </div>
        );
      }

      return marketLinkEl;
    } else if (bet.persistenceEnabled && !bet.canBeRemoved && !isGameBetSlip) {
      return (
        <BetPersistenceType
          offerId={bet.offerId}
          persistenceType={updatedPersistenceType}
          changePersistenceType={setUpdatedPersistenceType}
          side={bet.side}
          isDisabled={isUnmatchedBetDisabled}
          isFancySwapColors={isFancySwapColors}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    if (isFullyMatched && !updatedBet) {
      dispatch(setCurrentBetAction({ offerId: bet.offerId, action: ECurrentBetActions.FULLY_MATCHED }));
    }
  }, [isFullyMatched]);

  useEffect(() => {
    if (matchType === MatchTypes.UNMATCHED && (isMarketClosed || isLapsed)) {
      setMessageType('info');
      dispatch(setCurrentBetCanBeRemoved({ offerId: bet.offerId, canBeRemoved: true }));
    }
  }, [isMarketClosed, isLapsed]);

  useEffect(() => {
    if (betslipMessage.isMessage && matchType === MatchTypes.MATCHED) {
      // Instantly placed bet message show in the top of BetSlip
      if (placedBets[bet.offerId] === EPlacementType.PLACE) {
        dispatch(removePlacedBet(bet.offerId));
        if (betslipMessage.isTriggeredByCashOut && bet.offerId === cashOutPlacedId) {
          dispatch(cleanCashOutPlacedId({ marketId: bet.marketId }));
        }
        // Updated bet message after fully matching
      } else if (placedBets[bet.oldOfferId] === EPlacementType.UPDATE) {
        dispatch(removePlacedBet(bet.oldOfferId));
      }
    }
  }, [betslipMessage.isMessage]);

  useEffect(() => {
    if (placedBetStatus && matchType === MatchTypes.UNMATCHED && !getIsPartiallyMatched(bet)) {
      dispatch(removePlacedBet(bet.offerId));
    }
  }, [placedBetStatus, matchType, bet]);

  useEffect(() => {
    if (isUnmatchedBetDisabled) {
      setMessageType('info');
      prevIsUnmatchedBetDisabled.current = true;
    } else if (prevIsUnmatchedBetDisabled.current && !isMarketClosed) {
      setMessageType(null);
      prevIsUnmatchedBetDisabled.current = false;
    }
  }, [isUnmatchedBetDisabled]);

  useEffect(() => {
    if (
      isFillOrKillEnabled &&
      (bet.action === ECurrentBetActions.CANCELLING || bet.action === ECurrentBetActions.EDITING) &&
      isCancelled(bet)
    ) {
      dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));
    } else if (
      betslipMessage.isMessage &&
      matchType === MatchTypes.MATCHED &&
      bet.action === ECurrentBetActions.FULLY_MATCHED_AFTER_EDITING
    ) {
      // Bet was fully matched after updating
      dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));
    }
  }, [bet.offerState, bet.action, betslipMessage.isMessage, bet.sizeRemaining, bet.sizeMatched]);

  useEffect(() => {
    if (+bet.sizeCancelled > 0 && +bet.sizeRemaining === 0) {
      // Bet was cancelled
      setMessageType('info');
    }
  }, [bet.sizeCancelled, bet.sizeRemaining]);

  useEffect(() => {
    if (matchType === MatchTypes.UNMATCHED) {
      const isPartiallyMatched = getIsPartiallyMatched(bet);
      let showPartiallyMatchedNotification = true;

      if (isPartiallyMatched && !bet.hidePartiallyMatchedNotification) {
        const hiddenPartialUnmatchedNotifications: Record<string, number[]> = JSON.parse(
          localStorage.getItem(HIDDEN_PARTIAL_MATCHED_NOTIFICATIONS_LOCAL_STORAGE_NAME) ?? '{}'
        );

        showPartiallyMatchedNotification =
          !username ||
          !hiddenPartialUnmatchedNotifications ||
          !Object.keys(hiddenPartialUnmatchedNotifications).length ||
          !hiddenPartialUnmatchedNotifications[username]?.includes(bet.offerId);
      }

      if (
        isFullyMatched ||
        (isPartiallyMatched && !bet.hidePartiallyMatchedNotification && showPartiallyMatchedNotification)
      ) {
        setMessageType('success');

        if (isLongPlacingMessage) {
          setIsLongPlacingMessage(false);
        }

        if (isFullyMatched) {
          dispatch(setCurrentBetCanBeRemoved({ offerId: bet.offerId, canBeRemoved: true }));
        }
      }
    }
  }, [bet.sizeRemaining, bet.sizeMatched, matchType, isFullyMatched, bet.hidePartiallyMatchedNotification, username]);

  useEffect(() => {
    if (isFirst && isFirstOpenedBetFocused && matchType === MatchTypes.UNMATCHED) {
      dispatch(setFirstOpenedBetFocused(false));
    }
  }, [isFirst, isFirstOpenedBetFocused, matchType]);

  useEffect(() => {
    if (isGameBetSlip) {
      dispatch(
        setCurrentBetAction({
          offerId: bet.offerId,
          action: null
        })
      );
    }
  }, [isGameBetSlip, gameDataRound, bet.offerId]);

  useEffect(() => {
    if (
      matchType === MatchTypes.UNMATCHED &&
      (bet.action !== ECurrentBetActions.EDITING ||
        (isFillOrKillEnabled && bet.action === ECurrentBetActions.EDITING)) &&
      (bet.offerState === BetsStatusesTypes.CANCELLED ||
        (Number(bet.sizeCancelled) > 0 && Number(bet.sizeRemaining) === 0))
    ) {
      dispatch(setCurrentBetCanBeRemoved({ offerId: bet.offerId, canBeRemoved: true }));
    }
  }, [bet.sizeCancelled, bet.sizeRemaining, bet.offerState]);

  const onFormChanged = ({
    price,
    size,
    profit,
    isPriceValid,
    isSizeValid,
    priceErrorMessage,
    sizeErrorMessage
  }: TBetslipFormProps) => {
    const { marketType, bettingType, eachWayDivisor, side: betType } = bet;

    const updatedData = {
      ...(!isUndefined(price) ? { changedPrice: price } : {}),
      ...(!isUndefined(size) ? { changedSize: size } : {}),
      ...(!isUndefined(profit) ? { changedProfit: profit } : {}),
      ...(!isUndefined(isPriceValid) ? { isPriceValid } : {}),
      ...(!isUndefined(isSizeValid) ? { isSizeValid } : {}),
      ...(priceErrorMessage ? { validationMessage: { text: priceErrorMessage, field: 'price' } } : {}),
      ...(sizeErrorMessage ? { validationMessage: { text: sizeErrorMessage, field: 'size' } } : {}),
      isValid:
        (!isUndefined(isPriceValid) ? isPriceValid : bet.isPriceValid) &&
        (!isUndefined(isSizeValid) ? isSizeValid : bet.isSizeValid)
    };

    const betPrice = bet.changedPrice || bet.averagePrice || bet.price;
    const betSize = bet.changedSize || bet.sizeRemaining;

    if (isUndefined(profit) && !isUndefined(price)) {
      updatedData.changedProfit = calculateLiability(price, betSize, {
        marketType,
        bettingType,
        eachWayDivisor,
        betType
      });
    } else if (isUndefined(profit) && !isUndefined(size)) {
      updatedData.changedProfit = calculateLiability(betPrice, size, {
        marketType,
        bettingType,
        eachWayDivisor,
        betType
      });
    }

    if (!isUndefined(profit) && isUndefined(size)) {
      updatedData.changedSize = calculateSize(bet.price, profit, { bettingType: bet.bettingType });
    }

    if (
      bet.validationMessage?.text &&
      ((!isUndefined(price) && price !== bet.changedPrice) ||
        (!isUndefined(size) && size !== bet.changedSize) ||
        (!isUndefined(profit) && profit !== bet.changedProfit))
    ) {
      updatedData.validationMessage = { text: '', field: '' };
    }

    dispatch(updateOfferPlacementData({ offerId: bet.offerId, ...updatedData }));
  };

  const onEnterClick = useCallback(() => {
    if (matchType === MatchTypes.UNMATCHED && isPlaceBetsWithEnterKeyEnabled && isUpdateEnabled) {
      dispatch(setPlacedBetsToUpdate({ offerId: bet.offerId, placementType: EPlacementType.UPDATE }));
    }
  }, [matchType, bet, isUpdateEnabled, isPlaceBetsWithEnterKeyEnabled]);

  return (
    <div
      data-offer-id={`${bet.offerId}${bet.isCombined ? '_combined' : ''}`}
      data-placed-date={bet.placedDate}
      data-event-name={bet.eventName}
      data-market-name={bet.marketName}
      ref={isMatchedType ? undefined : ref}
      data-market-id={bet.marketId}
      data-event-id={bet.eventId}
      data-market-prices={true}
      className={classNames(
        styles.openedBet,
        styles[`openedBet__${bet.side?.toLowerCase()}`],
        styles[`openedBet__${lowerCaseMatchType}`],
        branding.OPENED_BET,
        branding[matchType],
        {
          [branding.FANCY_SWAP]: isFancySwapColors,
          [styles.openedBet__combined]: bet.isCombined,
          [styles.openedBet__combined__first]: bet.isCombined && isFirst,
          [branding.BET_CASHED_OUT]: isPNCEnabled && bet.triggeredByCashOut,
          [branding[bet.side]]: !isUnmatchedBetDisabled,
          [styles.openedBet__disabled]: isUnmatchedBetDisabled,
          [branding.DISABLED]: isUnmatchedBetDisabled
        }
      )}
    >
      {!isSortedByEvents && isMatchedType && (
        <>
          {isEventLinkEnabled ? (
            <Link className={styles.eventName} to={eventLink}>
              {bet.eventName}
            </Link>
          ) : (
            <span className={classNames(styles.eventName, betslipBranding.OPENED_BET_PLACED_TIME)}>
              {bet.eventName}
            </span>
          )}
        </>
      )}
      <div className={styles.openedBet__betForm}>
        <p className={styles.openedBet__selectionName}>
          {`${bet.selectionName}${bet.bettingType === BettingType.LINE ? ` @${BETSLIP_LINE_INT_ODDS}` : ''}`}
        </p>
        <BetForm
          betPrice={updatedPrice}
          betAveragePrice={bet.averagePrice}
          betSize={updatedSize}
          betProfit={updatedProfit}
          betType={bet.side}
          currency={bet.currency}
          bettingType={bet.bettingType}
          marketId={bet.marketId}
          marketType={bet.marketType}
          lineRangeInfo={lineRangeInfo}
          eachWayDivisor={bet.eachWayDivisor}
          priceLadderDescription={bet.priceLadderDescription}
          mode={
            matchType === MatchTypes.UNMATCHED &&
            !isUnmatchedBetDisabled &&
            !bet.canBeRemoved &&
            bet.action !== ECurrentBetActions.CANCELLING &&
            bet.action !== ECurrentBetActions.EDITING
              ? EPlaceBetsStates.SELECT
              : EPlaceBetsStates.CONFIRM
          }
          onFormChanged={onFormChanged}
          onEnterClick={onEnterClick}
          betIndex={betIndex}
          focusedField={focusedField}
          totalTabFields={tabulation.OPENED_BET_TABS}
          isCombined={bet.isCombined}
          isUnmatchedOpenedBetTab
          offerId={bet.offerId}
          validationMessage={bet.validationMessage}
          matchType={matchType}
          bet={bet}
        />
      </div>
      {getContentAfterBetForm()}
      {isEachWay && bet.eachWayDivisor && (
        <p className={styles.openedBet__eachWayLabel}>
          {t('market.each.way.termsNoPref', { odds: bet.eachWayDivisor, places: bet.numberOfWinners })}
        </p>
      )}
      {matchType === MatchTypes.UNMATCHED && !isEachWay && (
        <div className={styles.openedBet__labels}>
          <BetLabels
            price={updatedPrice}
            size={updatedSize}
            handicap={bet.handicap}
            betType={bet.side}
            marketType={bet.marketType}
            bettingType={bet.bettingType}
            eventTypeId={String(bet.eventTypeId)}
            lineRangeInfo={{
              marketUnit: bet.marketUnit || '',
              minUnitValue: bet.minUnitValue,
              maxUnitValue: bet.maxUnitValue,
              interval: bet.interval
            }}
            eachWayDivisor={bet.eachWayDivisor}
            handicapType={bet.lineSide}
            selectionName={bet.selectionName}
            eventName={bet.eventName}
            currency={bet.currency}
            placementType={bet.betType}
            isSwapColors={isFancySwapColors}
          />
        </div>
      )}
      {matchType === MatchTypes.UNMATCHED && progressBarLabel && !unmatchedBetError && (
        <UnmatchedBetProgressBar label={progressBarLabel} containerClassName={styles.progress} offerId={bet.offerId} />
      )}
      {unmatchedBetError && matchType === MatchTypes.UNMATCHED && (
        <Notification
          type="warning"
          message={unmatchedBetError}
          onClose={() => dispatch(removeBetSlipUnmatchedOfferError(bet.offerId))}
          isDismissible={bet.canBeRemoved}
          className={styles.notification}
        />
      )}
      {matchType === MatchTypes.UNMATCHED &&
        (isLongPlacingMessage || (betMessage && messageType)) &&
        !unmatchedBetError && (
          <BetMessage
            type={isLongPlacingMessage ? 'info' : messageType}
            message={isLongPlacingMessage ? t('betslip.labels.longProcessing') : betMessage}
            onClose={() => {
              setMessageType(null);
              if (matchType === MatchTypes.UNMATCHED && getIsPartiallyMatched(bet)) {
                dispatch(updateOfferPlacementData({ offerId: bet.offerId, hidePartiallyMatchedNotification: true }));

                // Logic to add offerIds to localStorage to not show partially matched notifications if they were closed by user
                if (username) {
                  const hiddenPartialUnmatchedNotifications: Record<string, number[]> = JSON.parse(
                    localStorage.getItem(HIDDEN_PARTIAL_MATCHED_NOTIFICATIONS_LOCAL_STORAGE_NAME) ?? '{}'
                  );
                  if (username in hiddenPartialUnmatchedNotifications) {
                    localStorage.setItem(
                      HIDDEN_PARTIAL_MATCHED_NOTIFICATIONS_LOCAL_STORAGE_NAME,
                      JSON.stringify({
                        ...hiddenPartialUnmatchedNotifications,
                        [username]: [...hiddenPartialUnmatchedNotifications[username], bet.offerId]
                      })
                    );
                  } else {
                    localStorage.setItem(
                      HIDDEN_PARTIAL_MATCHED_NOTIFICATIONS_LOCAL_STORAGE_NAME,
                      JSON.stringify({ ...hiddenPartialUnmatchedNotifications, [username]: [bet.offerId] })
                    );
                  }
                }
              }
              dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));
            }}
            displayCloseBtn={getIsPartiallyMatched(bet) && messageType === 'success'}
            displayMoreInformation={isFullyMatched && messageType === 'success'}
          />
        )}
      {matchType === MatchTypes.UNMATCHED && bet.validationMessage?.text && (
        <BetMessage
          type="warning"
          message={bet.validationMessage.text}
          onClose={() => {
            dispatch(updateOfferPlacementData({ offerId: bet.offerId, validationMessage: null }));
          }}
          displayCloseBtn
        />
      )}
      {matchType === MatchTypes.UNMATCHED && (unmatchedBetError || !bet.canBeRemoved) && (
        <BetActions
          bet={bet}
          updatedPrice={updatedPrice}
          updatedSize={updatedSize}
          updatedPersistenceType={updatedPersistenceType}
          isSizeValid={isSizeValidValue}
          isUpdateEnabled={isUpdateEnabled}
          currency={bet.currency}
          betIndex={betIndex}
          isLast={isLast}
          isDisabled={isUnmatchedBetDisabled}
          setUnmatchedBetMessageType={setMessageType}
          setIsLongPlacingMessage={setIsLongPlacingMessage}
          isLongPlacingMessage={isLongPlacingMessage}
        />
      )}
      {!bet.isCombined && <BetInfo bet={bet} matchType={matchType} />}
    </div>
  );
};

export default memo(OpenedBet);
