import { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { DEFAULT_CURRENCY } from 'constants/app';
import { ASIAN_BETSLIP_PLACEHOLDERS } from 'constants/asianView';
import { asianViewBetslipBranding as branding, asianViewComponents as components } from 'constants/branding';
import { useCurrency } from 'hooks/useCurrency';
import { useStakeRegexPattern } from 'hooks/usePrecision';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { getCurrency, getIsOperatorBettingLimitsEnabled } from 'redux/modules/appConfigs/selectors';
import { AsianViewSizeValidationType, TAsianViewPlaceBetErrorMessageId } from 'redux/modules/asianViewBetslip/type';
import { BetTypes, TProfit, TSize } from 'types/bets';
import { EInputFieldTypes } from 'types/betslip';
import { validateSize } from 'utils/size';

import styles from './styles.module.scss';

export interface IInput {
  type: EInputFieldTypes;
  sizeInputRef: RefObject<HTMLInputElement>;
  identifier?: string;
  size?: TSize;
  profit?: TProfit;
  betType: BetTypes;
  currencyCode?: string;
  validationMessage?: string;
  readOnly: boolean;
  disabled?: boolean;
  index?: number;
  isHovered?: boolean;
  setValidationMessage: ({
    message,
    validationType,
    messageId
  }: {
    message: string;
    validationType?: AsianViewSizeValidationType;
    messageId?: null | TAsianViewPlaceBetErrorMessageId;
  }) => void;
  updateBet: ({ size, profit }: { size?: TSize; profit?: TProfit }) => void;
  minSizeFormatted?: string;
  maxSizeFormatted?: string;
  isBackground?: boolean;
}

const Input = ({
  type,
  sizeInputRef,
  identifier,
  size,
  profit,
  betType,
  currencyCode = DEFAULT_CURRENCY,
  validationMessage = '',
  readOnly,
  disabled = false,
  index = 0,
  isHovered,
  setValidationMessage,
  updateBet,
  isBackground = false
}: IInput) => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const { t } = useTranslation();

  const defaultCurrency = useSelector(getCurrency);
  const isOperatorBettingLimitsEnabled = useSelector(getIsOperatorBettingLimitsEnabled);

  const currency = useCurrency(currencyCode);
  const sizeRegexPattern = useStakeRegexPattern();

  const inputRef = useRef<HTMLInputElement>(null);

  const fieldId = `${identifier}_${type}`;
  const placeholder = ASIAN_BETSLIP_PLACEHOLDERS[type]?.[betType] ?? '';
  const inputValue = (type === EInputFieldTypes.SIZE ? size : profit) ?? '';
  const isError = type === EInputFieldTypes.SIZE && !!validationMessage;
  const isBack = betType === BetTypes.BACK;

  useUpdateEffect(() => {
    if (!isTouched) {
      setIsTouched(true);
    }
  }, [inputValue]);

  const updateBetData = (value: TSize) => {
    updateBet(type === EInputFieldTypes.SIZE ? { size: value } : { profit: value });
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!readOnly) {
      const changedValue = e.target.value
        .trim()
        .replace(/[^0-9.]/g, '')
        .replace(/\.\./g, '.');
      const isValueValid = changedValue === '' || sizeRegexPattern.test(changedValue);

      if (!isValueValid) {
        updateBetData(inputValue);
      } else if (inputValue !== changedValue || isValueValid) {
        updateBetData(changedValue);
      }

      if (!(isError && validationMessage)) {
        setValidationMessage({ message: '' });
      }
    }
  };

  const onBlurHandler = () => {
    if (isTouched && inputValue !== '') {
      const validation = validateSize({
        size,
        betType,
        currency,
        defaultCurrency,
        currencyCode,
        isOperatorBettingLimitsEnabled
      });

      if (!validation.isValid && validation.errorMessage?.text) {
        if (type === EInputFieldTypes.SIZE) {
          updateBet({ size: validation.validValue });
        }

        setValidationMessage({
          message: t(validation.errorMessage.text, (validation.errorMessage.params as object) ?? {}),
          validationType: validation.type,
          messageId: validation.messageId
        });

        if (type === EInputFieldTypes.SIZE && sizeInputRef.current && !validationMessage) {
          sizeInputRef.current.focus();
        }
      }

      if (validation.isValid && validationMessage && type === EInputFieldTypes.SIZE) {
        setValidationMessage({ message: '', validationType: null, messageId: null });
      }

      if (type === EInputFieldTypes.PROFIT) {
        updateBet({ size });
      }
    }
  };

  useEffect(() => {
    if (index > 0) {
      return;
    }

    if (type === EInputFieldTypes.SIZE && sizeInputRef.current) {
      sizeInputRef.current.focus();
    } else {
      if (
        inputRef.current &&
        ((betType === BetTypes.BACK && type === EInputFieldTypes.SIZE) ||
          (betType === BetTypes.LAY && type === EInputFieldTypes.PROFIT))
      ) {
        inputRef.current.focus();
      }
    }
  }, [betType, type]);

  return (
    <div className={styles.inputWrap}>
      <input
        ref={type === EInputFieldTypes.SIZE ? sizeInputRef : inputRef}
        id={fieldId}
        type="text"
        className={classNames(styles.input, components.INPUT, {
          [styles.input__back]: isBack && isBackground,
          [styles.input__lay]: !isBack && isBackground,
          [styles.input__error]: isError,
          [styles.input__filled]: !isUndefined(inputValue) && inputValue !== '',
          [styles.input__hover]: isHovered && type === EInputFieldTypes.SIZE,
          [branding.ERROR_INPUT]: isError
        })}
        value={inputValue}
        placeholder=""
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        readOnly={readOnly}
        disabled={disabled}
      />
      <label className={styles.inputWrap__label} htmlFor={fieldId}>
        {t(placeholder)}
      </label>
    </div>
  );
};

export default Input;
