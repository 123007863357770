import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { toNumber } from 'lodash';

import Loader, { CircleColors } from 'components/Loader';
import { asianViewBetslipBranding as branding, asianViewComponents, asianViewIcons } from 'constants/branding';
import { ERROR_BET_IS_EXPIRED, VALIDATION_ERROR_BET_OUTDATED_ODDS } from 'constants/placement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getAsianCurrentBetByOfferId } from 'redux/modules/asianViewCurrentBets/selectors';
import { removePlacedQuickBet, updatePlacedQuickBet } from 'redux/modules/asianViewQuickBetting';
import {
  getAsianIsPlacedBetsLoading,
  getAsianQuickPlacedBetsAmount
} from 'redux/modules/asianViewQuickBetting/selectors';
import { TAsianQuickPlacedBet } from 'redux/modules/asianViewQuickBetting/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { getMarketPricesBettingTypeById } from 'redux/modules/marketsPrices/selectors';
import { OddsType } from 'types';
import { EAsianPlacedBetMessageType } from 'types/asianView';
import { BetTypes } from 'types/bets';
import { getMarketTypes } from 'utils/market';
import convertOdds from 'utils/oddsConverter';

import PlacedBetsNav from '../PlacedBetsNav';
import PlacedMessage from '../PlacedMessage';
import ProgressBar from '../ProgressBar';

import styles from './styles.module.scss';

interface IPlacedOfferProps {
  quickBet: TAsianQuickPlacedBet;
}
const PlacedOffer = ({ quickBet }: IPlacedOfferProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentBet = useSelector(getAsianCurrentBetByOfferId(toNumber(quickBet.offerId)));
  const bettingType = useSelector(getMarketPricesBettingTypeById(quickBet.marketId));
  const isPlacedBetsLoading = useSelector(getAsianIsPlacedBetsLoading);
  const totalBets = useSelector(getAsianQuickPlacedBetsAmount);
  const bet = quickBet.offer || currentBet;
  const { isAsianHandicap } = getMarketTypes(quickBet?.marketType ?? '', bettingType);

  const formattedDate = useLongDateFormat({
    isTimeFirst: true,
    isShortYear: false,
    time: bet?.placedDate ?? quickBet.updateTime ?? 0,
    hasYear: true,
    divider: ' ',
    dateDivider: '-',
    isMonthNumeric: true,
    isAsian: true
  });

  const [formattedTime, formattedDay] = formattedDate.split(' ');

  const oddsType = bet?.oddsType || quickBet.oddsType || OddsType.DEC;
  const oddsValue = convertOdds(bet?.averagePrice || quickBet.price || '', oddsType);
  const oddsTypeLabel = t(`asianView.labels.betslip.oddsType.${oddsType}`);
  const isBack = quickBet.betType === BetTypes.BACK;
  const isNavEnabled = totalBets > 1;
  const isInPlay = !!(quickBet.isInPlay || bet?.inPlay);

  const isPlacedOffer = bet && bet.offerState === BetsStatusesTypes.MATCHED;
  const isCancelledOffer = quickBet.offerStatus === BetsStatusesTypes.CANCELLED;
  const isExpired = quickBet.offerStatus === BetsStatusesTypes.EXPIRED;

  const isPlacedWithBetterOdds =
    isPlacedOffer && (isBack ? bet?.averagePrice > bet?.price : bet?.averagePrice < bet?.price);

  let placedMessageType = null;
  let placedMessageText = '';

  const messageParams = { odds: convertOdds(bet?.averagePrice || '', oddsType), oddsType: oddsTypeLabel };

  if (!!quickBet.placementError) {
    placedMessageType = EAsianPlacedBetMessageType.ERROR;
    placedMessageText = quickBet.placementError ?? '';
  } else if (isExpired) {
    placedMessageType = EAsianPlacedBetMessageType.ERROR;
    placedMessageText = t(ERROR_BET_IS_EXPIRED);
  } else if (isCancelledOffer) {
    placedMessageType = EAsianPlacedBetMessageType.ERROR;
    placedMessageText = t(VALIDATION_ERROR_BET_OUTDATED_ODDS);
  } else if (isPlacedOffer && isPlacedWithBetterOdds) {
    placedMessageType = EAsianPlacedBetMessageType.PLACED_WITH_BETTER_ODDS;
    placedMessageText = t('asianView.labels.betslip.successPlacedBetWithBetterOdds', messageParams);
  } else if (isPlacedOffer) {
    placedMessageType = EAsianPlacedBetMessageType.PLACED;
    placedMessageText = t('asianView.labels.betslip.successPlacedBet', messageParams);
  }

  const isError = placedMessageType === EAsianPlacedBetMessageType.ERROR;
  const size = quickBet.size || 0;
  const price = quickBet.price || 0;
  const liability = isBack ? size : (+price - 1) * +size;
  const betLiability = isError ? liability : bet?.liability ?? 0;

  const { noFormattedAmount: liabilityFormatted } = useFormatCurrency(betLiability || liability, bet?.currency, {
    isCheckIndian: true,
    noRounding: true
  });

  let scoreLabel = isInPlay && isAsianHandicap ? bet?.score ?? quickBet?.score ?? '' : '';

  if (scoreLabel) {
    scoreLabel = `(${scoreLabel})`;
  }

  const onClose = () => {
    if (placedMessageText && quickBet.isHidden) {
      dispatch(removePlacedQuickBet(quickBet.offerId || quickBet.identifier));
    } else {
      dispatch(
        updatePlacedQuickBet({
          ...quickBet,
          isClosed: true
        })
      );
    }
  };

  return (
    <div
      className={classNames(styles.bet, branding.RESULTING_POP_UP, {
        [styles.bet__back]: isBack,
        [styles.bet__lay]: !isBack
      })}
    >
      <div className={styles.bet__header}>
        {isNavEnabled && <PlacedBetsNav />}
        {isInPlay && (
          <span className={classNames(styles.bet__live, branding.LIVE_LABEL)}>
            {t('asianView.labels.betslip.live')}
          </span>
        )}
        <span className={styles.bet__eventName}>{bet?.eventName ?? quickBet.eventName}</span>
        <i onClick={onClose} className={classNames('biab_tour-icon-close', styles.bet__closeIcon)} />
      </div>
      <div className={classNames(styles.bet__border, branding.BET_SEPARATOR)} />
      <div className={styles.bet__marketName}>
        {bet ? (
          <span>{`${bet?.marketName} – ${bet?.selectionName} ${scoreLabel}`}</span>
        ) : (
          <span>{`${quickBet?.marketName} – ${quickBet?.selectionName} ${scoreLabel}`}</span>
        )}
      </div>
      <div className={styles.bet__info}>
        <div
          className={classNames(styles.bet__odds, {
            [styles.bet__odds__up]: isPlacedWithBetterOdds
          })}
        >
          @<span className={styles.bet__odds__value}>{oddsValue}</span> ({oddsTypeLabel})
          {isPlacedWithBetterOdds && (
            <i className={classNames(styles.bet__odds__icon, 'fa2 fa2-sort-up biab_indicator')} />
          )}
        </div>
        <span>{t(isBack ? 'asianView.labels.betslip.backBetFor' : 'asianView.labels.betslip.layBetAgainst')}</span>
      </div>
      <div className={classNames(styles.bet__info, styles.bet__info__topDash, branding.BET_SEPARATOR)}>
        <div>{t('asianView.labels.betslip.liability')}:</div>
        <div>
          <strong>{liabilityFormatted}</strong>
        </div>
      </div>
      {placedMessageType && quickBet?.offerId && !isError && (
        <div className={classNames(styles.bet__info, styles.bet__info__date)}>
          <div>
            {t('asianView.labels.betslip.offerId')}: {bet?.offerId}
          </div>
          <div className={styles.bet__info__dateTime}>
            <span>{formattedTime}</span>
            <span>{formattedDay}</span>
          </div>
        </div>
      )}
      {placedMessageType && <PlacedMessage type={placedMessageType} message={placedMessageText} />}
      {!placedMessageType && <ProgressBar />}
      {false && isPlacedBetsLoading && (
        <div className={styles.loader__wrap}>
          <div className={styles.loader__overlay} />
          <Loader
            circleColor={CircleColors.BLUE}
            className={`${styles.loader__circle} ${asianViewIcons.QUICK_BETTING_SPINNER}`}
          />
        </div>
      )}
      {isNavEnabled && (
        <div className={classNames(styles.bet__totalBets, branding.RESULTING_POP_UP_COUNTER)}>{totalBets}</div>
      )}
      <button type="button" className={classNames(asianViewComponents.PRIMARY_BTN, styles.btn)} onClick={onClose}>
        {t('asianView.labels.betslip.ok')}
      </button>
    </div>
  );
};

export default PlacedOffer;
