import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { AsianViewBetStatusResponse } from 'redux/modules/asianViewBetslip/type';

import {
  failureFetchMobileBetStatuses,
  failurePlaceMobileBet,
  fetchMobileBetStatuses,
  placeMobileBet,
  successFetchMobileBetStatuses,
  successPlaceMobileBet
} from './index';
import { TPlacedMobileBetByMarket } from './type';

function* getPlaceMobileBetWorker(action: ReturnType<typeof placeMobileBet>) {
  try {
    const response: TPlacedMobileBetByMarket = yield call(api.asianPlacement.placeBets, action.payload);

    Object.keys(response).forEach(marketId => {
      response[marketId].identifier = action.payload[marketId][0].betUuid;
    });

    yield put(successPlaceMobileBet(response));
  } catch (error) {
    yield put(failurePlaceMobileBet({ data: action.payload, error }));
  }
}

function* getMobileBetStatusesWorker(action: ReturnType<typeof fetchMobileBetStatuses>) {
  try {
    const response: AsianViewBetStatusResponse = yield call(
      api.asianPlacement.getBetsStatuses,
      action.payload.offerIds.join()
    );

    yield put(successFetchMobileBetStatuses(response));
  } catch (error: any) {
    yield put(failureFetchMobileBetStatuses({ offerIds: action.payload.offerIds, error: error?.response?.data }));
  }
}

export default function* saga() {
  yield all([
    takeEvery(placeMobileBet.type, getPlaceMobileBetWorker),
    takeEvery(fetchMobileBetStatuses.type, getMobileBetStatusesWorker)
  ]);
}
