import axios from 'axios';

import { AsianViewSection } from 'constants/asianView';
import { urls } from 'redux/api/urls';
import { Devices, TFetchPropertiesResponse, TSuccessFetchCurrency } from 'redux/modules/appConfigs/type';
import {
  IAsianSport,
  IGetSportsPayload,
  IRequestCounterPayload,
  IRequestEventsCounterPayload
} from 'redux/modules/asianSportsNavigation/type';
import {
  AsianMarket,
  EventsResponse,
  IFetchSearch,
  ISelectionCoupon,
  TFetchAsianViewCountMarketsPayload,
  TFetchAsianViewListPayload
} from 'redux/modules/asianView/type';
import { AsianViewBetStatusResponse, TAsianPlaceBet } from 'redux/modules/asianViewBetslip/type';
import { AsianViewFavouriteResponse } from 'redux/modules/asianViewFavourites/type';
import { TFetchAsianViewLeaguesFilterPayload } from 'redux/modules/asianViewLeaguesFilter/type';
import { AsianViewPopularLink } from 'redux/modules/asianViewPopularLinks/type';
import { TAsianPlaceQuickBetPayloadData, TPlacedQuickBetsByMarket } from 'redux/modules/asianViewQuickBetting/type';
import { BannerSections } from 'redux/modules/banners/type';
import { CreateAutoCashOutPayload, TSetCashOut } from 'redux/modules/cashOut/type';
import {
  ICountryCompetitionBySportPayload,
  IGetEventPayload,
  TFetchTimeFiltersPayload,
  TMultiMarketResponse,
  TNavigationResponse
} from 'redux/modules/competitions/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { FancyMarketTypes } from 'redux/modules/fancyMarket/type';
import { TFavorite } from 'redux/modules/favorites/type';
import { TFetchResultHistoryPayload } from 'redux/modules/games/type';
import { TFetchInPLayMarketsPayload } from 'redux/modules/inPlay/type';
import { IGetMarketPayload } from 'redux/modules/market/type';
import { FetchMetaPageContentPayload, FetchMetaPageContentResponse } from 'redux/modules/meta/type';
import { TMultiMarketTabDetails, TMultiMarketTopDetails } from 'redux/modules/multiMarket/type';
import {
  ICancelBetPayload,
  IGetPLPayload,
  IGetPLTotalPayload,
  TAccountProductsPayload,
  TFetchStatementPayload,
  TGetBetPayload,
  TMyBetsResponse
} from 'redux/modules/myBets/type';
import { PageContentType } from 'redux/modules/pages/types';
import {
  TCancelAllBetsDataPayload,
  TCancelBet,
  TEditBet,
  TPlaceBetsDataPayload,
  TPlacedBetsByMarket
} from 'redux/modules/placement/type';
import { TGetPSFooterPayload } from 'redux/modules/pokerstar/types';
import { TRace } from 'redux/modules/racing/type';
import {
  ResponsibleBettingReportsAvailability,
  ResponsibleBettingSettings,
  ResponsibleBettingTools,
  TCancelTimeLimit,
  TConfirmLossLimit
} from 'redux/modules/responsibleBetting/type';
import { TSportPageDetailsPayload, TSportPageDetailsResponse } from 'redux/modules/sportPage/type';
import { IGetTooltipsTranslations } from 'redux/modules/tooltip/type';
import { TFetchTop5ViewDetailsResponse } from 'redux/modules/top5View/type';
import { IGetStepPayload } from 'redux/modules/tours/type';
import { TUserAccountSettings } from 'redux/modules/user/type';
import { HLSUrlResponse, VideoStreamUrlsResponse } from 'redux/modules/videoStream/type';
import { Method, TEventUpdatedData, TFailureActionPayload, ViewBy } from 'types';
import { InPlaySport } from 'types/inPlay';
import { IMarket } from 'types/markets';

import { fetchRequest } from './request';

export default {
  app: {
    getPsFooter: ({ license, locale }: TGetPSFooterPayload): Promise<string> => {
      return axios.get(urls.app.getPsFooterEndpoint(license, locale)).then(res => res.data);
    },
    getAppConfigs: (): Promise<TFetchPropertiesResponse> => {
      return fetchRequest(Method.GET, urls.app.configsEndpoint());
    },
    getTranslations: (data: string, asianViewEnabled?: boolean): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.app.translationsEndpoint(data), {
        ...(asianViewEnabled && { quickLink: 'ASIAN_VIEW' })
      });
    },
    getContentPages: (): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.app.contentPagesEndpoint(), undefined, undefined, false);
    },
    getPageContent: (data: string): Promise<PageContentType> => {
      return fetchRequest(Method.GET, urls.app.pageContentEndpoint(data), undefined, undefined, false);
    },
    banners: (platform: Devices, section: BannerSections, locale: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.app.banners(), {
        platform,
        section,
        locale
      });
    },
    getCurrency: (): Promise<TSuccessFetchCurrency> => {
      return fetchRequest(Method.GET, urls.app.currencyEndpoint());
    },
    getCurrencies: (): Promise<TSuccessFetchCurrency[]> => {
      return fetchRequest(Method.GET, urls.app.currencies());
    },
    eventUpdates: (eventIds: string[]): Promise<TEventUpdatedData[]> => {
      return fetchRequest(Method.POST, urls.app.eventUpdates(), eventIds);
    },
    marketRules: (marketId: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.app.marketRules(marketId));
    },
    eventRules: (eventId: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.app.eventRules(eventId));
    },
    getTooltips: ({ locale, platform, asianViewEnabled }: IGetTooltipsTranslations): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.app.tooltipsEndpoint(), {
        localeCode: locale,
        platform,
        ...(asianViewEnabled && { quickLink: 'ASIAN_VIEW' })
      });
    },
    eventUpdatesById: (eventId: string): Promise<TEventUpdatedData> => {
      return fetchRequest(Method.GET, urls.app.eventUpdatesById(eventId));
    },
    getCashOutPopUp: (language: string) => {
      return fetchRequest(Method.GET, urls.app.getCashOutPopUpEndpoint(language), undefined, undefined, false);
    }
  },
  auth: {
    login: (username: string, password: string): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.auth.login(), {
        username,
        password
      });
    },
    logout: (): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.auth.logout(), undefined, undefined, false);
    },
    verify: (username: string, password: string): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.auth.verify(), {
        username,
        password
      });
    }
  },
  user: {
    info: (asianViewEnabled: boolean): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.user.info(asianViewEnabled));
    },
    balance: (asianViewEnabled: boolean): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.user.balance(asianViewEnabled));
    },
    settings: (data: TUserAccountSettings): Promise<unknown> => {
      return fetchRequest(Method.PUT, urls.user.settings(), { ...data, lastModified: new Date().getTime() });
    }
  },
  sports: {
    getSports: (): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.sports.getSportsEndpoint());
    }
  },
  games: {
    getGames: (): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.games.getGamesEndpoint());
    },
    getCurrentGameData: (gameId: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.games.getCurrentGameData(gameId));
    },
    getGameRules: (gameId: string | number): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.games.getGameRules(gameId));
    },
    getResultsHistory: ({ channelId, page, gameId, betPlacedOnly }: TFetchResultHistoryPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.games.getResultsHistory({ channelId, page, gameId, betPlacedOnly }));
    }
  },
  races: {
    getNextRaces: (): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.races.getNextRacesEndpoint());
    }
  },
  competitions: {
    getCountriesAndCompetitions: (sportId: string | number): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.competitions.getCountriesAndCompetitionsEndpoint(sportId));
    },
    getCompetitionDetails: ({ eventId, showGroups }: IGetEventPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.competitions.getCompetitionDetails(eventId, showGroups));
    },
    getCountryCompetitionsBySportId: ({ countryId, sportId }: ICountryCompetitionBySportPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.competitions.getCountryCompetitionsBySportId(countryId, sportId));
    },
    getEvent: ({ eventId, showGroups }: IGetEventPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.competitions.getEvent(eventId, showGroups));
    },
    getMarket: (marketId: string | number): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.competitions.getMarket(marketId));
    },
    getSportCompetition: ({ eventId, showGroups }: IGetEventPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.competitions.getSportCompetition(eventId, showGroups));
    },
    getGroup: ({ eventId, showGroups }: IGetEventPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.competitions.getGroup(eventId, showGroups));
    },
    getSports: (sportId: string | number): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.competitions.getSports(sportId));
    },
    getRace: (raceId: string | number): Promise<TNavigationResponse> => {
      return fetchRequest(Method.GET, urls.competitions.getRace(raceId));
    },
    getTimeFilters: (data: TFetchTimeFiltersPayload): Promise<string[]> => {
      return fetchRequest(Method.POST, urls.competitions.getTimeFilters(), data);
    },
    fetchMultiMarket: (eventId: string | number): Promise<TMultiMarketResponse> => {
      return fetchRequest(Method.GET, urls.competitions.fetchMultiMarket(eventId));
    }
  },
  search: (query: string, offset: number, size: number): Promise<unknown> => {
    return fetchRequest(Method.GET, urls.search(), { query, offset, size, groupBySports: true });
  },
  home: {
    marketsSection: (sportId: string, isMobile: boolean, sortBy?: ViewBy): Promise<unknown> => {
      const params = {
        sortBy,
        ...(isMobile ? { mobile: true } : {})
      };

      return fetchRequest(Method.GET, urls.home.marketsSection(sportId), params);
    }
  },
  marketOdds: {
    getMarketOdds: (): Promise<IMarket[]> => {
      return fetchRequest(Method.GET, urls.marketOdds.getMarketOddsEndpoint());
    }
  },
  racing: {
    horseRacing: (sportId: string, timeRange: string): Promise<TRace[]> => {
      return fetchRequest(Method.GET, urls.racing.horseRacing(sportId), { timeRange });
    }
  },
  featuredMarkets: {
    getFeaturedMarkets: (): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.featuredMarkets.getFeaturedMarketsEndpoint());
    }
  },
  placement: {
    getCurrentBets: (): Promise<TCurrentBet[]> => {
      return fetchRequest(Method.GET, urls.placement.getCurrentBetsEndpoint());
    },
    placeBets: (data: TPlaceBetsDataPayload): Promise<TPlacedBetsByMarket> => {
      return fetchRequest(Method.POST, urls.placement.getPlaceBetsEndpoint(), data);
    },
    cancelBets: (data: { [marketId: string]: TCancelBet[] }): Promise<TPlacedBetsByMarket> => {
      return fetchRequest(Method.POST, urls.placement.cancelBets, data);
    },
    editBets: (data: { [p: string]: TEditBet[] }): Promise<TPlacedBetsByMarket> => {
      return fetchRequest(Method.POST, urls.placement.getEditBetsEndpoint(), data);
    },
    getBetsStatuses: (betIds: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.placement.getBetsStatuses(betIds));
    },
    cancelAllBets: (data: TCancelAllBetsDataPayload) => {
      return fetchRequest(Method.POST, urls.placement.getCancelAllBetsEndpoint(), data);
    },
    getCancelActionStatuses: (actionId: number): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.placement.getCancelActionStatuses(actionId));
    }
  },
  myBets: {
    getBets: ({
      betsType,
      startDate,
      endDate,
      page,
      size,
      currency,
      asianView,
      bettingDay
    }: TGetBetPayload): Promise<TMyBetsResponse> => {
      return fetchRequest(Method.GET, urls.myBets.getMyBets(), {
        betsType,
        startDate,
        endDate,
        page,
        size,
        currency: currency || undefined,
        asianView: asianView || undefined,
        bettingDay: bettingDay || undefined
      });
    },
    getAccountStatement: ({
      startDate,
      endDate,
      page,
      size,
      accountStatementOrigin,
      currency,
      asianView,
      bettingDay
    }: TFetchStatementPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.myBets.getAccountStatement(), {
        startDate,
        endDate,
        page,
        size,
        accountStatementOrigin,
        currency: currency || undefined,
        asianView: asianView || undefined,
        bettingDay: bettingDay || undefined
      });
    },
    getCharges: (): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.myBets.charge());
    },
    getPL: ({
      startDate,
      endDate,
      page,
      size,
      eventTypeId,
      betType,
      currency,
      asianView,
      bettingDay
    }: IGetPLPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.myBets.getPL(), {
        startDate,
        endDate,
        page,
        size,
        eventTypeId: eventTypeId || undefined,
        betType,
        currency: currency || undefined,
        asianView: asianView || undefined,
        bettingDay: bettingDay || undefined
      });
    },
    getPLTotal: ({
      startDate,
      endDate,
      betType,
      eventTypeId,
      currency,
      asianView,
      bettingDay
    }: IGetPLTotalPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.myBets.getPLTotal(), {
        startDate,
        endDate,
        betType,
        eventTypeId: eventTypeId || undefined,
        currency: currency || undefined,
        asianView: asianView || undefined,
        bettingDay: bettingDay || undefined
      });
    },
    getSportsList: (currency?: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.myBets.sportsList(), { currency: currency || undefined });
    },
    getGamesList: (currency?: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.myBets.gamesList(), { currency: currency || undefined });
    },
    cancelBet: (bet: ICancelBetPayload): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.placement.cancelBets, bet);
    },
    accountProducts: (payload: TAccountProductsPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.myBets.accountProducts(), payload);
    }
  },
  settings: {
    getSettings: (language: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.settings.getSettings(language));
    }
  },
  inpLay: {
    inPlayMarkets: ({ id, payload, size, page }: TFetchInPLayMarketsPayload): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.inPlay.inPlayMarkets(id), payload, { page, size });
    },
    sports: (): Promise<InPlaySport[]> => {
      return fetchRequest(Method.GET, urls.inPlay.sports());
    }
  },
  event: {
    getEventDetails: (eventId: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.event.getEventDetailsEndpoint(eventId));
    },
    getEventGroup: (eventId: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.event.getEventGroupEndpoint(eventId));
    },
    getFancyEventDetails: (eventId: string, fancyMarketTypes: FancyMarketTypes) => {
      return fetchRequest(Method.GET, urls.event.getFancyEventDetailsEndpoint(eventId), { fancyMarketTypes });
    },
    getTop5ViewDetails: (eventId: string): Promise<TFetchTop5ViewDetailsResponse> => {
      return fetchRequest(Method.GET, urls.event.getTop5ViewDetailsEndpoint(eventId));
    },
    getMultiMarketDetails: (eventId: string): Promise<TMultiMarketTabDetails[]> => {
      return fetchRequest(Method.GET, urls.event.getMultiMarketTabsEndpoint(eventId));
    },
    getMultiMarketTopDetails: (eventId: string): Promise<TMultiMarketTopDetails> => {
      return fetchRequest(Method.GET, urls.event.getMultiMarketTopEndpoint(eventId));
    }
  },
  market: {
    getMarketDetails: ({ marketId, showGroups }: IGetMarketPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.market.getMarketDetailsEndpoint(marketId, showGroups));
    },
    getListViewMarkets: (sportId: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.market.getListViewMarkets(sportId));
    }
  },
  sportPage: {
    getDetails: ({ data, page, size }: TSportPageDetailsPayload): Promise<TSportPageDetailsResponse> => {
      const params: { page: number } | { page: number; size: number } = size === undefined ? { page } : { page, size };

      return fetchRequest(Method.POST, urls.sportPage.getDetails(), data, params);
    }
  },
  popular: {
    getPopularLinks: (): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.popular.getPopularLinks());
    }
  },
  plTable: {
    getAHPlTable: (id: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.plTable.getAsianPLTable(id));
    },
    getLinePlTable: (id: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.plTable.getLinePLTable(id));
    }
  },
  favorites: {
    getFavorites: (): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.favorites.favoriteEndpoint());
    },
    saveRemoveFavorite: (favorite: TFavorite): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.favorites.favoriteEndpoint(), favorite);
    }
  },
  tours: {
    getStep: ({ id, platform, locale }: IGetStepPayload): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.tours.getStep(id), { platform, locale });
    }
  },
  responsibleBetting: {
    getSettings: (): Promise<ResponsibleBettingSettings> => {
      return fetchRequest(Method.GET, urls.responsibleBetting.base());
    },
    updateSettings: (data: ResponsibleBettingTools): Promise<boolean> => {
      return fetchRequest(Method.PUT, urls.responsibleBetting.base(), data);
    },
    updateReminderLastDate: (): Promise<unknown> => {
      return fetchRequest(Method.PUT, urls.responsibleBetting.updateReminderLastDate());
    },
    updateDayLimitReminder: (): Promise<unknown> => {
      return fetchRequest(Method.PUT, urls.responsibleBetting.updateDayLimitReminder());
    },
    confirmLimit: (data: TConfirmLossLimit): Promise<boolean> => {
      return fetchRequest(Method.PUT, urls.responsibleBetting.confirmLimit(), data);
    },
    cancelTimeLimit: (data: TCancelTimeLimit): Promise<boolean> => {
      return fetchRequest(Method.PUT, urls.responsibleBetting.cancelTimeLimit(), data);
    },
    fetchReportsAvailability: (): Promise<ResponsibleBettingReportsAvailability> => {
      return fetchRequest(Method.GET, urls.responsibleBetting.fetchReportsAvailability());
    }
  },
  cashOut: {
    getCashOut: ({ ids, isAsianView }: { ids: string[]; isAsianView?: boolean }): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.cashOut.quote(isAsianView), ids);
    },
    getMarkets: ({
      page,
      size,
      sportId,
      eventId,
      isAsianView
    }: {
      page: number;
      size: number;
      sportId?: string;
      eventId?: string;
      isAsianView?: boolean;
    }) => {
      const params = {
        page,
        size,
        ...(sportId && { sportId }),
        ...(eventId && { eventId })
      };
      return fetchRequest(Method.GET, urls.cashOut.markets(isAsianView), params);
    },
    createAutoCashOut: (payload: CreateAutoCashOutPayload, isAsianView?: boolean): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.cashOut.createAutoCashOut(isAsianView), payload);
    },
    deleteAutoCashOut: (id: number, isAsianView?: boolean): Promise<unknown> => {
      return fetchRequest(Method.DELETE, urls.cashOut.deleteAutoCashOut(id, isAsianView));
    },
    getAutoCashOutMarkets: (ids: string[], isAsianView?: boolean): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.cashOut.getAutoCashOutMarkets(isAsianView), ids);
    },
    setCashOut: (payload: TSetCashOut, isAsianView?: boolean): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.cashOut.setCashOutMarkets(isAsianView), payload);
    },
    getCashOutStatus: (id: number, isAsianView?: boolean): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.cashOut.status(id, isAsianView));
    }
  },
  videoStream: {
    getVideoStreamUrls: (eventId: string): Promise<VideoStreamUrlsResponse> => {
      return fetchRequest(Method.GET, urls.videoStream(eventId));
    },
    getVideoStreamHlsUrl: (requestHlsUrl: string): Promise<HLSUrlResponse> => {
      // TODO if needed add common error handling when added for 'fetchRequest' or replace 'axios.get' with
      //  'fetchRequest, now if use 'fetchRequest' backend responds with CORS, most likely because of extra headers in
      //  'fetchRequest
      return axios.get(requestHlsUrl).then(res => res.data);
    }
  },
  meta: ({ page, id }: FetchMetaPageContentPayload): Promise<FetchMetaPageContentResponse> => {
    return fetchRequest(Method.GET, urls.meta(), { page, id });
  },
  asianNavigation: {
    getAsianSports: (payload: IGetSportsPayload): Promise<IAsianSport[]> => {
      return fetchRequest(Method.POST, urls.asianNavigation.getAsianSports(), payload);
    },
    getAsianSportCounter: ({ sportId, payload }: IRequestCounterPayload) => {
      return fetchRequest(Method.POST, urls.asianNavigation.getAsianSportCounter(sportId), payload);
    },
    getAsianSportEventsCounter: ({ sportId }: IRequestEventsCounterPayload) => {
      return fetchRequest(Method.GET, urls.asianNavigation.getAsianSportEventsCounter(sportId));
    }
  },
  asianView: {
    getMarketByTab: (payload: ISelectionCoupon): Promise<Record<AsianViewSection, AsianMarket>> => {
      const { sportId, sections, marketLink, itemId, viewType } = payload;
      return fetchRequest(Method.POST, urls.asianView.getMarketByTab(sportId, itemId), {
        sections,
        marketLink,
        viewType
      });
    },
    list: ({ id, offset, size, payload, page }: TFetchAsianViewListPayload): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.asianView.list(id, size, page, offset), payload);
    },
    competitions: ({ id, payload }: TFetchAsianViewLeaguesFilterPayload): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.asianView.competitions(id), payload);
    },
    getCountMarkets: ({ id, payload }: TFetchAsianViewCountMarketsPayload): Promise<unknown> => {
      return fetchRequest(Method.POST, urls.asianView.getCountMarkets(id), payload);
    },
    popularLinks: (): Promise<AsianViewPopularLink[]> => {
      return fetchRequest(Method.GET, urls.asianView.popularLinks());
    },
    marketRules: (marketId: string): Promise<unknown> => {
      return fetchRequest(Method.GET, urls.asianView.marketRules(marketId));
    }
  },
  asianViewFavourites: {
    getAsianViewFavourites: (): Promise<AsianViewFavouriteResponse[] | TFailureActionPayload> => {
      return fetchRequest(Method.GET, urls.asianView.asianViewFavourites());
    },
    saveRemoveAsianViewFavorite: (
      favorite: AsianViewFavouriteResponse
    ): Promise<AsianViewFavouriteResponse[] | TFailureActionPayload> => {
      return fetchRequest(Method.POST, urls.asianView.asianViewFavourites(), favorite);
    },
    getFavouritesEventsList: ({ offset, size, payload, page }: Omit<TFetchAsianViewListPayload, 'id'>) => {
      return fetchRequest(Method.POST, urls.asianView.asianViewFavouritesEventsList(size, page, offset), payload);
    }
  },
  asianSearch: {
    getEvents: (payload: IFetchSearch): Promise<EventsResponse> => {
      const { query, size, page, offset, body } = payload;
      return fetchRequest(Method.POST, urls.asianSearch.getEvents(query, size, page, offset), body);
    }
  },
  asianPlacement: {
    placeBets: (payload: { [key: string]: TAsianPlaceBet[] }): Promise<TPlacedBetsByMarket> => {
      return fetchRequest(Method.POST, urls.asianPlacement.placeBets, payload);
    },
    placeQuickBets: (data: TAsianPlaceQuickBetPayloadData): Promise<TPlacedQuickBetsByMarket> => {
      return fetchRequest(Method.POST, urls.placement.getAsianPlaceBetsEndpoint(), data);
    },
    getBetsStatuses: (betIds: string): Promise<AsianViewBetStatusResponse> => {
      return fetchRequest(Method.GET, urls.asianPlacement.getBetsStatuses, { betIds });
    },
    getAsianCurrentBets: (): Promise<TCurrentBet[]> => {
      return fetchRequest(Method.GET, urls.placement.getAsianViewCurrentBetsEndpoint);
    }
  },
  checkMarkets: (marketsIds: string[]) => {
    return fetchRequest(Method.GET, urls.checkMarkets(), { marketIds: marketsIds.join(',') });
  },
  checkSportsMarkets: (marketsIds: string[]) => {
    return fetchRequest(Method.GET, urls.checkSportsMarkets(), { marketIds: marketsIds.join(',') });
  },
  checkGamesMarkets: (marketsIds: string[]) => {
    return fetchRequest(Method.GET, urls.checkGamesMarkets(), { marketIds: marketsIds.join(',') });
  },
  profitLossTablePopUp: {
    avProfitLossTableData: (eventId: string) => {
      return fetchRequest(Method.GET, urls.profitLossTablePopUp.avProfitLossTableData(eventId));
    },
    avProfitLossTableMarketNames: (eventId: string) => {
      return fetchRequest(Method.GET, urls.profitLossTablePopUp.avProfitLossTableMarketNames(eventId));
    },
    getPLTablePopUpData: (eventId: string) => {
      return fetchRequest(Method.GET, urls.profitLossTablePopUp.plTablePopUpData(eventId));
    },
    profitLossTableMarketNames: (eventId: string) => {
      return fetchRequest(Method.GET, urls.profitLossTablePopUp.profitLossTableMarketNames(eventId));
    }
  }
};
